import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Popup, Divider, Icon, Feed } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { UserService } from "../../backend/axios/connector";
import { RootState } from "../../store/reducer";
import {
  AppNotificationType,
  AppNotification,
} from "../../store/notification/reducer";

interface Props {
  show?: boolean;
}
interface StateProps {
  email: string | undefined;
  notifications: AppNotification[];
  token: string | undefined;
}

const notificationToIcon = (notificationType: AppNotificationType) => {
  switch (notificationType) {
    case AppNotificationType.SUCCESS:
      return <Icon name="check" color="green" />;
    case AppNotificationType.WARNING:
      return <Icon name="warning sign" color="yellow" />;
    case AppNotificationType.ERROR:
      return <Icon name="warning circle" color="red" />;
  }
};

const NotificationPopup = (props: Props) => {
  const { t } = useTranslation();

  const [connectedToBackend, setConnectedToBackend] = useState(false);

  const { email, token, notifications } = useSelector<RootState, StateProps>(
    (state) => {
      return {
        email: state.user.email,
        token: state.user.token,
        notifications: state.notification.notifications,
      };
    }
  );

  const checkBackend = () => {
    UserService.getCurrentUser()
      .then(() => {
        setConnectedToBackend(true);
      })
      .catch(() => {
        setConnectedToBackend(false);
        setOpen(true);
      });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (token !== undefined) {
        checkBackend();
      }
    }, 10000);
    if (token !== undefined) {
      checkBackend();
    }

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    setOpen(true);
  }, [notifications]);

  const [open, setOpen] = React.useState(props.show);

  return (
    <Popup
      wide
      position="bottom right"
      trigger={<Icon name="info" />}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <React.Fragment>
        <Feed>
          {token !== undefined ? (
            connectedToBackend ? (
              <Feed.Event>
                <Icon name="check" color="green" />
                <Feed.Content>
                  <Feed.Summary>Server is functional</Feed.Summary>
                </Feed.Content>
              </Feed.Event>
            ) : (
              <Feed.Event>
                <Icon name="warning" color="red" />
                <Feed.Content>
                  <Feed.Summary>Unable to connect to server</Feed.Summary>
                </Feed.Content>
              </Feed.Event>
            )
          ) : (
            <Feed.Event>
              <Icon name="question" color="yellow" />
              <Feed.Content>
                <Feed.Summary>
                  Login to regular check server health
                </Feed.Summary>
              </Feed.Content>
            </Feed.Event>
          )}

          {email !== "" && (
            <Feed.Event>
              <Icon name="user" />
              <Feed.Content>
                <Feed.Summary>Logged in as {email}</Feed.Summary>
              </Feed.Content>
            </Feed.Event>
          )}

          {notifications.map((notification) => (
            <>
              <Divider fitted />
              <Feed.Event>
                {notificationToIcon(notification.type)}
                <Feed.Content>
                  <Feed.Summary>{t(notification.message)}</Feed.Summary>
                  <Feed.Extra text>{notification.description}</Feed.Extra>
                  <Feed.Meta>
                    <Feed.Date>
                      <Icon name="history" />
                      {notification.date?.toLocaleTimeString()}
                    </Feed.Date>
                  </Feed.Meta>{" "}
                </Feed.Content>
              </Feed.Event>
            </>
          ))}
        </Feed>
      </React.Fragment>
    </Popup>
  );
};

export default NotificationPopup;
