import { Dispatch } from "redux";
import { UserService } from "../../backend/axios/connector";
import store from "../store";

import { UserDispatchAction, UserActionType } from "./reducer";

export class UserDispatcher {
  private readonly dispatch: Dispatch<UserDispatchAction>;

  constructor(dispatch: Dispatch<UserDispatchAction>) {
    this.dispatch = dispatch;
  }
  login = (email: string, name: string, token: string) =>
    this.dispatch({
      type: UserActionType.LOGIN,
      payload: { email, name, token },
    });

  logout = () => {
    this.dispatch({ type: UserActionType.LOGOUT, payload: {} });
  };

  refresh = () => {
    UserService.getCurrentUser()
      .then((data) => data.data)
      .then((user) => {
        this.dispatch({
          type: UserActionType.LOGIN,
          payload: {
            name: user.username,
            email: user.email,
            token: store.getState().user.token,
          },
        });
      });
  };
}

export default UserDispatcher;
