import { TagTodoLink } from './../../backend/axios/api';
import { Action, Reducer } from "redux";

export interface TagTodoLinkState {
  loading: boolean;
  loaded: boolean;
  tagTodoLinks: TagTodoLink[];
  error: boolean;
}

export const initialState: TagTodoLinkState = {
  loading: false,
  loaded: false,
  tagTodoLinks: [],
  error: false,
};

export enum TagTodoLinkActionType {
  GET_CURRENT_TAG_TODO_LINKS_TRIGGER = "GET_CURRENT_TAG_TODO_LINKS_TRIGGER",
  GET_CURRENT_TAG_TODO_LINKS_REQUEST = "GET_CURRENT_TAG_TODO_LINKS_REQUEST",
  GET_CURRENT_TAG_TODO_LINKS_SUCCESS = "GET_CURRENT_TAG_TODO_LINKS_SUCCESS",
  GET_CURRENT_TAG_TODO_LINKS_FAILURE = "GET_CURRENT_TAG_TODO_LINKS_FAILURE",
  TAG_TODO_LINKS_CLEAR_ERROR_MESSAGE = "TAG_TODO_LINKS_CLEAR_ERROR_MESSAGE",
}
export interface TagTodoLinkDispatchAction extends Action<TagTodoLinkActionType> {
  payload: Partial<TagTodoLinkState>;
}

export const tagTodoLinkReducer: Reducer<TagTodoLinkState, TagTodoLinkDispatchAction> = (
  state = initialState,
  action
): TagTodoLinkState => {
  switch (action.type) {
    case TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_REQUEST:
      return { ...state, loading: true };

    case TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        tagTodoLinks: action.payload.tagTodoLinks ? action.payload.tagTodoLinks : state.tagTodoLinks,
      };
    case TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_FAILURE:
      return { ...state, error: true, loading: false };
    case TagTodoLinkActionType.TAG_TODO_LINKS_CLEAR_ERROR_MESSAGE:
      return { ...state, error: false };

    default:
      return state;
  }
};
