import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Button, Icon, Label, Table } from "semantic-ui-react";

import { Trans } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

import TagEditDialog from "./tagEditDialog";
import TagDeleteDialog from "./tagDeleteDialog";
import { Tag, TagChangePosition } from "../../backend/axios/api";
import TagDispatcher from "../../store/tag/dispatcher";
import { TagService } from "../../backend/axios/connector";
import { RootState } from "../../store/reducer";

interface Props {
  lightweight: boolean;
  tags: Tag[];
  tagLoading: boolean;
}
const TagList = (props: Props) => {
  const dispatch = useDispatch();
  const tagDispatcher = new TagDispatcher(dispatch);

  const onReload = () => {
    tagDispatcher.loadTags();
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      let nextTagId: TagChangePosition["beforeTag"];
      {
        let tagIndex = result.destination.index;
        if (result.source.index < result.destination.index) {
          tagIndex = tagIndex + 1;
        }
        if (tagIndex < props.tags.length) {
          nextTagId = props.tags[tagIndex].id;
        }
      }

      /*tagContext.setTags(arrayImmutableMove(
          tagContext.tags,
          result.source.index,
          result.destination.index
        ),
      );*/

      TagService.changeTagPosition(props.tags[result.source.index].id, {
        beforeTag: nextTagId,
      }).then(() => {
        tagDispatcher.loadTags();
      });
    }
  };

  return (
    <>
      <Header as="h1">
        <Trans i18nKey="tag.title" />
      </Header>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"tagDroppableId"}>
          {(provided) => (
            <table
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="ui compact table"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Trans i18nKey="tag.table.label.name" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <Trans i18nKey="tag.table.label.options" />
                    {(props.lightweight && props.tagLoading && (
                      <Icon name={"refresh"} loading={props.tagLoading} />
                    )) ||
                      props.lightweight || (
                        <Icon
                          name={"refresh"}
                          loading={props.tagLoading}
                          onClick={onReload}
                        />
                      )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {props.tags.map((el, index) => (
                  <Draggable
                    key={el.id}
                    draggableId={el.id as string}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Table.Cell>
                          <Label tag>{el.name}</Label>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <Icon name="arrows alternate vertical" />
                          <TagEditDialog
                            initialTag={el}
                            trigger={<Icon name="edit" />}
                          />
                          <TagDeleteDialog
                            tag={el}
                            trigger={<Icon name="trash" color={"red"} />}
                          />
                        </Table.Cell>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Table.Body>

              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan="4" textAlign="right">
                    {props.lightweight || (
                      <TagEditDialog
                        trigger={
                          <Button
                            icon
                            labelPosition="left"
                            primary
                            size="small"
                          >
                            <Icon name="add" />
                            <Trans i18nKey="tag.table.action.add" />
                          </Button>
                        }
                      />
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

interface StateProps {
  tags: Tag[];
  tagLoading: boolean;
}
export const SimpleTagList = () => {
  const { tags, tagLoading } = useSelector<RootState, StateProps>((state) => {
    return {
      tags: state.tag.tags,
      tagLoading: state.tag.loading,
    };
  });

  return <TagList lightweight={false} tags={tags} tagLoading={tagLoading} />;
};

export default TagList;
