import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TodoDispatcher from "../../store/todo/dispatcher";
import {
  Button,
  DropdownProps,
  Dimmer,
  Message,
  Loader,
  Form,
  Modal,
  Checkbox,
  TextArea,
  TextAreaProps,
} from "semantic-ui-react";
import { AxiosPromise } from "axios";
import { Trans, useTranslation } from "react-i18next";
import {
  Todo,
  TodoStatusEnum,
  Tag,
  TodoPatch,
  TagTodoLink,
} from "../../backend/axios/api";
import { dateToDateStr } from "../../../util/dateHelper";
import { TodoService, TagTodoLinkService } from "../../backend/axios/connector";
import { RootState } from "../../store/reducer";
import NotificationDispatcher from "../../store/notification/dispatcher";
import { AppNotificationType } from "../../store/notification/reducer";
import TagTodoLinkDispatcher from "../../store/tagTodoLink/dispatcher";

interface TodoPatchWithTag extends TodoPatch {
  tags?: string[];
}
interface Props {
  initialTodo?: Todo;
  trigger: React.ReactNode;
}
interface StateProps {
  tags: Tag[];
  tagTodoLinks: TagTodoLink[];
}

const TodoEditDialog = (props: Props) => {
  const { t } = useTranslation();

  const { tags, tagTodoLinks } = useSelector<RootState, StateProps>((state) => {
    return {
      tags: state.tag.tags,
      tagTodoLinks: state.tagTodoLink.tagTodoLinks,
    };
  });
  const dispatch = useDispatch();
  const notificationDispatcher = new NotificationDispatcher(dispatch);
  const tagTodoLinkDispatcher = new TagTodoLinkDispatcher(dispatch);
  const todoDispatcher = new TodoDispatcher(dispatch);

  const [open, setOpen] = React.useState(false);

  const [addAnother, setAddAnother] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [hasSubmitError, setHasSubmitError] = React.useState(false);

  const [formData, setFormData] = React.useState<TodoPatchWithTag>({});
  React.useEffect(() => {
    if (props.initialTodo) {
      setFormData(props.initialTodo);
    }
  }, [props.initialTodo]);
  React.useEffect(() => {
    setFormData({
      ...props.initialTodo,
      ...formData,
      tags: tags
        .filter(
          (tag) =>
            props.initialTodo &&
            props.initialTodo.id &&
            tagTodoLinks
              .filter((el) => el.tagId === tag.id)
              .map((el) => el.todoId)
              .indexOf(props.initialTodo.id) !== -1
        )
        .map((el) => el.id),
    });
  }, [props.initialTodo, tags, tagTodoLinks]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let adjustedValue: any = value;
    if (target.type === "date") {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        adjustedValue = undefined;
      } else {
        adjustedValue = date;
      }
    }

    setFormData({
      ...formData,
      [name]: adjustedValue,
    });
  };
  const handleTextAreaChange = (name: string, data: TextAreaProps) => {
    setFormData({
      ...formData,
      [name]: data.value,
    });
  };
  const handleSelectChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const value = data.value;
    const name = data.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = () => {
    setSubmitting(true);

    const todo: TodoPatch = {
      ...formData,
    };

    const axiosAction = props.initialTodo?.id
      ? TodoService.updateTodo(props.initialTodo.id, todo)
      : TodoService.addTodo(todo);

    axiosAction
      .then((response) => {
        var promises: Array<AxiosPromise<any>> = [];

        // update tag assingment
        if (formData.tags !== undefined) {
          const formTags = formData.tags;
          formTags
            .filter(
              (formTag) =>
                tagTodoLinks
                  .filter((el) => el.todoId === response.data.id)
                  .map((tagTodoLink) => tagTodoLink.tagId)
                  .indexOf(formTag) === -1
            )
            .forEach((formDataTagEl) => {
              //add
              promises.push(
                TagTodoLinkService.addLinkTagWithTodo(
                  formDataTagEl,
                  response.data.id
                )
              );
            });
          tagTodoLinks
            .filter((tagTodoLink) => tagTodoLink.todoId === response.data.id)
            .filter((tagTodoLink) => formTags.indexOf(tagTodoLink.tagId) === -1)

            .forEach((tagTodoLink) => {
              //remove
              promises.push(
                TagTodoLinkService.deleteLinkTagWithTodo(
                  tagTodoLink.tagId,
                  tagTodoLink.todoId
                )
              );
            });
        }

        return Promise.all(promises).then(() => {
          todoDispatcher.loadTodos();
          tagTodoLinkDispatcher.loadTagTodoLinks();
        });
      })
      .then((response) => {
        setHasSubmitError(false);

        if (!addAnother) {
          setOpen(false);
        }
      })
      .catch((error) => {
        setHasSubmitError(true);

        notificationDispatcher.addNotification({
          type: AppNotificationType.ERROR,
          message:
            error?.response?.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.task.save",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      trigger={props.trigger}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeIcon={true}
    >
      <Modal.Header>
        {props.initialTodo?.id ? (
          <Trans i18nKey="todo.dialog.edit.title" />
        ) : (
          <Trans i18nKey="todo.dialog.add.title" />
        )}
      </Modal.Header>
      <Modal.Content>
        <Dimmer active={submitting}>
          <Loader>
            <Trans i18nKey="loading" />
          </Loader>
        </Dimmer>

        <Form>
          <Form.Group>
            <Form.Input
              label="Title"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              width={10}
            />
            <Form.Input
              label="Url"
              type="text"
              name="url"
              value={formData.url}
              onChange={handleInputChange}
              width={6}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              search
              label="Status"
              type="text"
              name="status"
              value={formData.status}
              options={Object.values(TodoStatusEnum).map((el) => {
                return { value: el, text: el };
              })}
              onChange={handleSelectChange}
              selection={true}
              fluid={true}
            />
            <Form.Dropdown
              search
              label="Tags"
              type="text"
              name="tags"
              value={formData.tags ? formData.tags : []}
              options={[
                ...tags.map((tag) => {
                  return { value: tag.id, text: tag.name };
                }),
              ]}
              onChange={handleSelectChange}
              selection={true}
              multiple={true}
              fluid={true}
              clearable={true}
            />
            <Form.Input
              label="Reminder Date"
              type="date"
              name="dueDate"
              value={dateToDateStr(formData.dueDate)}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <TextArea
              placeholder="More information..."
              value={formData.description}
              onInput={(event, data) =>
                handleTextAreaChange("description", data)
              }
            />
          </Form.Group>
        </Form>

        {hasSubmitError && (
          <Message
            error
            header={t("axios.error.server")}
            content={
              props.initialTodo?.id
                ? t("axios.error.task.update")
                : t("axios.error.task.add")
            }
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          {props.initialTodo?.id ? (
            <Trans i18nKey="todo.dialog.edit.button.cancel" />
          ) : (
            <Trans i18nKey="todo.dialog.add.button.cancel" />
          )}
        </Button>
        {!props.initialTodo?.id && (
          <Checkbox
            toggle
            checked={addAnother}
            label={t("todo.dialog.add.checkbox.another")}
            onChange={(e, data) => setAddAnother(!!data.checked)}
          />
        )}
        <Button
          onClick={onSubmit}
          positive
          labelPosition="right"
          icon="checkmark"
          content={
            props.initialTodo?.id ? (
              <Trans i18nKey="todo.dialog.edit.button.submit" />
            ) : (
              <Trans i18nKey="todo.dialog.add.button.submit" />
            )
          }
        />
      </Modal.Actions>
    </Modal>
  );
};

export default TodoEditDialog;
