import React from "react";
import { Container, Image, Menu, Icon, Input, Form } from "semantic-ui-react";

import NotificationPopup from "./notification";
import LoginMenu from "./loginMenu";
import BrowserNotification from "./browserNotification";

import logo from "./../../../resources/images/logo.svg";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";

interface Props extends ReduxProps {}
interface ReduxProps {
  searchEnabled: boolean;
}

const AppMenu = (props: Props) => {
  const [showSearchRedirect, setShowSearchRedirect] = React.useState("");

  return (
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item as="a" header href="/#/">
          <Image size="mini" src={logo} style={{ marginRight: "1.5em" }} />
          Home
        </Menu.Item>
        <Menu.Item as="a" href="/#/timer">
          <Icon name="clock outline" size="big" />
          Timer
        </Menu.Item>

        {/*<Dropdown item simple text='Dropdown'>
          <Dropdown.Menu>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Header Item</Dropdown.Header>
            <Dropdown.Item>
              <i className='dropdown icon' />
              <span className='text'>Submenu</span>
              <Dropdown.Menu>
                <Dropdown.Item>List Item</Dropdown.Item>
                <Dropdown.Item>List Item</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>*/}

        <Menu.Menu position="right">
          {props.searchEnabled && (
            <Menu.Item>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.currentTarget);
                  const term = formData.get("search")?.toString();

                  if (term) {
                    setShowSearchRedirect("/search/" + term);
                  }
                }}
              >
                <Input
                  name="search"
                  icon={{ name: "search", circular: true, link: true }}
                  placeholder="Search..."
                />
                {showSearchRedirect && <Redirect to={showSearchRedirect} />}
              </Form>
            </Menu.Item>
          )}
          <Menu.Item>
            <NotificationPopup />
          </Menu.Item>
          <LoginMenu />;
        </Menu.Menu>

        <BrowserNotification />
      </Container>
    </Menu>
  );
};

const AppMenuWithRedux = () => {
  const { searchEnabled } = useSelector<RootState, ReduxProps>((state) => {
    return {
      searchEnabled: state.config.features?.search || false,
    };
  });

  return <AppMenu searchEnabled={searchEnabled}></AppMenu>;
};

export default AppMenuWithRedux;
