import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";
import { Menu, Icon } from "semantic-ui-react";
import { Trans } from "react-i18next";
import { Tag } from "../../backend/axios/api";
import { TimerStatus } from "../../store/timer/reducer";

interface Props {
  vertical?: boolean;
}
interface StateProps {
  tags: Tag[];

  timerState: TimerStatus;
  tickCountdownStr: string;
}
const MenuSidebar = (props: Props) => {
  const { tags, timerState, tickCountdownStr } = useSelector<
    RootState,
    StateProps
  >((state) => {
    return {
      tags: state.tag.tags,

      timerState: state.timer.timerState,
      tickCountdownStr: state.timer.tickCountdownStr,
    };
  });

  return (
    <Menu compact inverted vertical={props.vertical !== false}>
      <Menu.Item>
        <Menu.Header href="/#/todo">
          <Trans i18nKey="menu.todo" />
        </Menu.Header>
        <Menu.Menu>
          <Menu.Item href="/#/todo?status=open">
            <Trans i18nKey="menu.todo.open" />
          </Menu.Item>
          <Menu.Item href="/#/todo?status=completed">
            <Trans i18nKey="menu.todo.completed" />
          </Menu.Item>
          <Menu.Item href="/#/todo?status=scheduled">
            <Trans i18nKey="menu.todo.scheduled" />
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header href="/#/tag">
          <Trans i18nKey="menu.tag" />
        </Menu.Header>
        <Menu.Menu>
          {tags.map((el) => (
            <Menu.Item href={"/#/tag/" + el.id} key={el.id}>
              {el.name}
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Menu.Item>
      {timerState !== "STOP" && (
        <Menu.Item>
          <Menu.Header href="/#/timer">
            <Trans i18nKey="menu.timer" />
          </Menu.Header>
          <Menu.Menu>
            <Menu.Item href="/#/timer">
              {timerState === "INTERVAL" ? (
                <Icon name="briefcase" />
              ) : (
                <Icon name="coffee" />
              )}{" "}
              {tickCountdownStr}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MenuSidebar;
