import { SearchRequest, SearchResult } from "../../backend/axios/api";
import { Action, Reducer } from "redux";

export interface SearchState {
  term: string | undefined;
  loading: boolean;
  loaded: boolean;
  searchResult?: SearchResult;
  error: boolean;
}

export const initialState: SearchState = {
  term: undefined,
  loading: false,
  loaded: false,
  searchResult: undefined,
  error: false,
};

export enum SearchActionType {
  RUN_SEARCH_TRIGGER = "RUN_SEARCH_TRIGGER",
  RUN_SEARCH_REQUEST = "RUN_SEARCH_REQUEST",
  RUN_SEARCH_SUCCESS = "RUN_SEARCH_SUCCESS",
  RUN_SEARCH_FAILURE = "RUN_SEARCH_FAILURE",
  RUN_SEARCH_CLEAR_ERROR_MESSAGE = "RUN_SEARCH_CLEAR_ERROR_MESSAGE",
}
export interface SearchDispatchAction extends Action<SearchActionType> {
  payload: Partial<SearchState> | SearchRequest;
}

export const searchReducer: Reducer<SearchState, SearchDispatchAction> = (
  state = initialState,
  action
): SearchState => {
  switch (action.type) {
    case SearchActionType.RUN_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        term: (action.payload as Partial<SearchState>).term,
        searchResult: undefined,
      };

    case SearchActionType.RUN_SEARCH_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        searchResult: (action.payload as Partial<SearchState>).searchResult
          ? (action.payload as Partial<SearchState>).searchResult
          : state.searchResult,
      };
    case SearchActionType.RUN_SEARCH_FAILURE:
      return { ...state, error: true, loading: false };
    case SearchActionType.RUN_SEARCH_CLEAR_ERROR_MESSAGE:
      return { ...state, error: false };

    default:
      return state;
  }
};
