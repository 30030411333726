import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducer";
import { useTranslation } from "react-i18next";

import { useNotification } from "../../../util/notificationHelper";
import { TimerStatus } from "../../store/timer/reducer";

interface Props {}
interface StateProps {
  timerState: TimerStatus;
}

export const BrowserNotification = (props: Props) => {
  const { t } = useTranslation();

  const audioRef = React.createRef<HTMLAudioElement>();
  const notification = useNotification();

  const { timerState } = useSelector<RootState, StateProps>((state) => {
    return {
      timerState: state.timer.timerState,
    };
  });

  const [previousTimerState, setPreviousTimerState] =
    useState<TimerStatus>("STOP");
  useEffect(() => {
    if (timerState !== previousTimerState) {
      if (timerState === "INTERVAL") {
        notification(t("timer.notification.interval"));
        audioRef.current?.play();
      } else if (timerState === "BREAK" || timerState === "LONGBREAK") {
        notification(t("timer.notification.break"));
        audioRef.current?.play();
      }
    }
    setPreviousTimerState(timerState);
  }, [timerState]); // eslint-disable-line react-hooks/exhaustive-deps

  return <audio src="eventually.mp3" ref={audioRef} />;
};

export default BrowserNotification;
