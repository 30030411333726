import { Action, Reducer } from "redux";

import { prependToLength } from "../../../util/stringHelper";

export type TimerStatus = "STOP" | "INTERVAL" | "BREAK" | "LONGBREAK";
export interface TimerState {
  intervalDuration: number;
  breakDuration: number;
  longBreakDuration: number;
  intervalsTillLongBreak: number;

  tick: number;
  tickCountdown: number;
  tickCountdownStr: string;
  currentInterval: number;
  timerState: TimerStatus;

  tasks: string[];
}

export const initialState: TimerState = {
  intervalDuration: 25,
  breakDuration: 5,
  longBreakDuration: 15,
  intervalsTillLongBreak: 3,

  tick: 0,
  tickCountdown: 0,
  tickCountdownStr: 25 + ":00m left",
  currentInterval: 0,
  timerState: "STOP",

  tasks: [],
};

export interface TimerDispatchAction extends Action<TimerActionType> {
  payload: Partial<TimerState>;
}

export enum TimerActionType {
  TIMER_START = "TIMER_START",
  TIMER_RESET = "TIMER_RESET",

  TIMER_TICK = "TIMER_TICK",
  TIMER_CONFIG_UPDATE = "TIMER_CONFIG_UPDATE",

  TIMER_TASKS_UPDATE = "TIMER_TASKS_UPDATE",
}

export const timerReducer: Reducer<TimerState, TimerDispatchAction> = (
  state = initialState,
  action
): TimerState => {
  switch (action.type) {
    case TimerActionType.TIMER_START:
      return {
        ...state,
        timerState: "INTERVAL",
      };
    case TimerActionType.TIMER_RESET:
      return {
        ...state,
        tick: 0,
        tickCountdown: 0,
        currentInterval: 0,
        timerState: "STOP",
        tickCountdownStr: state.intervalDuration + ":00m left",
      };
    case TimerActionType.TIMER_TICK:
      let newState = state;

      const upperTickLimit =
        60 *
        (state.timerState === "INTERVAL"
          ? state.intervalDuration
          : state.timerState === "BREAK"
          ? state.breakDuration
          : state.longBreakDuration);

      if (state.timerState !== "STOP") {
        if (state.tick < upperTickLimit) {
          newState.tick = state.tick + 1;
          newState.tickCountdown = upperTickLimit - newState.tick;
          newState.tickCountdownStr =
            Math.floor(newState.tickCountdown / 60).toString() +
            ":" +
            prependToLength((newState.tickCountdown % 60).toString(), 2, "0") +
            "m left";
        } else if (state.timerState === "INTERVAL") {
          //interval is full now
          if (state.currentInterval < state.intervalsTillLongBreak) {
            newState.timerState = "BREAK";
          } else {
            newState.timerState = "LONGBREAK";
          }
          newState.tick = 0;
        } else if (state.timerState === "BREAK") {
          // break is over. Switch to interval
          newState.currentInterval += 1;
          newState.timerState = "INTERVAL";
          newState.tick = 0;
        } else if (state.timerState === "LONGBREAK") {
          newState.timerState = "STOP";
          newState.currentInterval = 0;
        }
      }
      return {
        ...state,
        ...newState,
      };
    case TimerActionType.TIMER_CONFIG_UPDATE:
      return {
        ...state,
        tickCountdownStr:
          action.payload.intervalDuration &&
          action.payload.timerState === "STOP"
            ? action.payload.intervalDuration + ":00m left"
            : state.tickCountdownStr,
        intervalDuration: action.payload.intervalDuration
          ? action.payload.intervalDuration
          : state.intervalDuration,
        breakDuration: action.payload.breakDuration
          ? action.payload.breakDuration
          : state.breakDuration,
        longBreakDuration: action.payload.longBreakDuration
          ? action.payload.longBreakDuration
          : state.longBreakDuration,
        intervalsTillLongBreak: action.payload.intervalsTillLongBreak
          ? action.payload.intervalsTillLongBreak
          : state.intervalsTillLongBreak,
      };
    case TimerActionType.TIMER_TASKS_UPDATE:
      return {
        ...state,
        tasks: action.payload.tasks ? action.payload.tasks : state.tasks,
      };
    default:
      return state;
  }
};
