import React from "react";

export const storageItemGet = <S extends unknown>(
  key: string,
  defaultValue: S
): S => {
  return localStorage.getItem(key)
    ? (JSON.parse(localStorage.getItem(key) as string) as S)
    : defaultValue;
};
export const storageItemPut = (key: string, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};
export const storageItemDelete = (key: string) => {
  return localStorage.removeItem(key);
};

export const useStateWithLocalStorage = <S extends unknown | undefined>(
  localStorageKey: string,
  defaultValue: S
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const localStorageValueGetter = () => localStorage.getItem(localStorageKey);

  const [value, setValue] = React.useState<S>(
    localStorageValueGetter() !== null
      ? (JSON.parse(localStorageValueGetter() as string) as S)
      : defaultValue
  );

  React.useEffect(() => {
    if (value !== undefined) {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    } else {
      localStorage.removeItem(localStorageKey);
    }
  }, [localStorageKey, value]);

  return [value, setValue];
};
