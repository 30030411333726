import React from "react";
import { Button, Container, Header, Icon } from "semantic-ui-react";
import { BASE_PATH } from "../../backend/axios/base";

interface Props {}
const LoginPage = (props: Props) => {
  return (
    <>
      <Header as='h2'>What is Todo App</Header>
      <Container>
        <a href="/#/about">Learn about Todo App</a>.
      </Container>

      <Header as='h2'>Continue with</Header>
      <Container textAlign="center">
        <Button color="google plus" as="a" href={BASE_PATH + "/auth/google/login"}>
          <Icon name="google" size="large" circular />
          Google
        </Button>

        <Container text>
          By signing in, you supply your basic account data (email, username) to
          this service. This service uses your information to customize your
          experience, while also using external services. Find out more in our{" "}
          <a href="/#/privacy">Privacy Policy</a>.
        </Container>
      </Container>
    </>
  );
};

export default LoginPage;
