import { Configuration } from "./configuration";
import {
  AuthApi,
  TagApi,
  TodoApi,
  LinkApi,
  UserApi,
  SearchApi,
  ConfigApi,
} from "./api";
import { BASE_PATH } from "./base";

export const apiConfiguration = new Configuration({
  basePath: BASE_PATH,
});

export const AuthService = new AuthApi(apiConfiguration);
export const ConfigService = new ConfigApi(apiConfiguration);
export const SearchService = new SearchApi(apiConfiguration);
export const TagService = new TagApi(apiConfiguration);
export const TagTodoLinkService = new LinkApi(apiConfiguration);
export const TodoService = new TodoApi(apiConfiguration);
export const UserService = new UserApi(apiConfiguration);
