import { Action, Reducer } from "redux";
import { Config } from "../../backend/axios/api";

export interface ConfigState extends Config {}

export const initialState: ConfigState = {
  running: false,
  features: {
    search: false,
  },
};

export enum ConfigActionType {
  GET_CONFIG_TRIGGER = "GET_CONFIG_TRIGGER",
  GET_CONFIG_REQUEST = "GET_CONFIG_REQUEST",
  GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS",
  GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE",
}

export interface ConfigDispatchAction extends Action<ConfigActionType> {
  payload?: Partial<ConfigState>;
}

export const ConfigReducer: Reducer<ConfigState, ConfigDispatchAction> = (
  state = initialState,
  action
): ConfigState => {
  switch (action.type) {
    case ConfigActionType.GET_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
