import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TodoDispatcher from "../../store/todo/dispatcher";
import { useParams } from "react-router";
import {
  Header,
  Dropdown,
  Input,
  Label,
  Button,
  Icon,
  Table,
} from "semantic-ui-react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

import { Trans, useTranslation } from "react-i18next";
import queryString from "query-string";

import {
  Todo,
  TodoStatusEnum,
  Tag,
  TagTodoLink,
  TodoChangePosition,
} from "../../backend/axios/api";
import TodoEditDialog from "./todoEditDialog";
import TodoDeleteDialog from "./todoDeleteDialog";
import { dateToDateStr } from "../../../util/dateHelper";
import {
  getQueryOfLocationHash,
  updateLocationHashPath,
} from "../../../util/urlHelper";
import { RootState } from "../../store/reducer";
import { TodoService } from "../../backend/axios/connector";

function stringToEnumEntry(enumeration: any, value: string): any | null {
  for (const k of Object.keys(enumeration))
    if (enumeration[k] === value) return enumeration[k];
  return null;
}

const parseFilterStatus = (key: string): TodoStatusEnum[] => {
  let stati: TodoStatusEnum[] = [];
  const search = queryString.parse(
    getQueryOfLocationHash(window.location.hash)
  ) as any;
  if (search[key]) {
    const statiStrs = search[key].split(",") as string[];
    for (let statiStr of statiStrs) {
      const enumStr = stringToEnumEntry(TodoStatusEnum, statiStr);
      if (enumStr) {
        stati.push(enumStr);
      }
    }
  }

  return stati;
};

interface Props {
  lightweight: boolean;
  tagId?: string;
}
interface ReduxProps {
  tags: Tag[];
  tagTodoLinks: TagTodoLink[];
  todos: Todo[];
  todosLoading: boolean;
}
export const TodoList = (props: Props & ReduxProps) => {
  const { t } = useTranslation();

  const tag = props.tags.find((tag) => tag.id === props.tagId);
  const tagTodoLinksForTag = props.tagTodoLinks.filter(
    (tagTodoLink) => tag && tagTodoLink.tagId === tag.id
  );

  const dispatch = useDispatch();
  const todoDispatcher = new TodoDispatcher(dispatch);

  const onReload = () => {
    todoDispatcher.loadTodos();
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      let nextTodoId: TodoChangePosition["beforeTodo"];
      {
        let todoIndex = result.destination.index;
        if (result.source.index < result.destination.index) {
          todoIndex = todoIndex + 1;
        }
        if (todoIndex < props.todos.length) {
          nextTodoId = props.todos[todoIndex].id;
        }
      }
      /*tagContext.setTags(arrayImmutableMove(
          tagContext.tags,
          result.source.index,
          result.destination.index
        ),
      );*/
      TodoService.changeTodoPosition(props.todos[result.source.index].id, {
        beforeTodo: nextTodoId,
      }).then(() => {
        todoDispatcher.loadTodos();
      });
    }
  };

  const [filterStatus, setFilterStatus] = useState<TodoStatusEnum[]>(
    parseFilterStatus("status")
  );
  const [filterStatusSearch, setFilterStatusSearch] = useState<string>("");
  useEffect(() => {
    updateLocationHashPath("status", filterStatus);
  }, [filterStatus]);
  useEffect(() => {
    function hashHandler() {
      setFilterStatus(parseFilterStatus("status"));
    }

    window.addEventListener("hashchange", hashHandler);
    return () => {
      window.removeEventListener("hashchange", hashHandler);
    };
  });

  const todoRows = props.todos
    .filter(
      (todo) =>
        !tag ||
        tagTodoLinksForTag.find(
          (tagTodoLink) => tagTodoLink.todoId === todo.id
        ) !== undefined
    )
    .filter(
      (el) =>
        filterStatus.length === 0 ||
        !el.status ||
        filterStatus.indexOf(el.status) !== -1
    );

  const statusFilter = (
    <Dropdown multiple icon="filter">
      <Dropdown.Menu>
        <Input
          icon="search"
          iconPosition="left"
          className="search"
          onClick={(e: any) => e.stopPropagation()}
          onChange={(e) => setFilterStatusSearch(e.target.value)}
        />

        <Dropdown.Menu scrolling>
          <Dropdown.Item
            key={"all"}
            text={"All"}
            onClick={() => setFilterStatus([])}
          />
          {Object.values(TodoStatusEnum)
            .filter(
              (el) =>
                el.toLowerCase().indexOf(filterStatusSearch.toLowerCase()) !==
                -1
            )
            .map((el) => (
              <Dropdown.Item
                key={el}
                value={el}
                text={el}
                onClick={() => setFilterStatus([el])}
              />
            ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      <Header as="h1">
        {tag ? (
          t("todo.titleWithTag", { tag: tag.name })
        ) : (
          <Trans i18nKey="todo.title" />
        )}
      </Header>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"todoDroppableId"}>
          {(provided) => (
            <table
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="ui compact table"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Trans i18nKey="todo.table.label.title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Trans i18nKey="todo.table.label.status" />
                    {statusFilter}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Trans i18nKey="todo.table.label.tags" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Trans i18nKey="todo.table.label.dueDate" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Trans i18nKey="todo.table.label.options" />
                    {(props.lightweight && props.todosLoading && (
                      <Icon name={"refresh"} loading={props.todosLoading} />
                    )) ||
                      props.lightweight || (
                        <Icon
                          name={"refresh"}
                          loading={props.todosLoading}
                          onClick={onReload}
                        />
                      )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {todoRows.map((el, index) => (
                  <Draggable
                    key={el.id}
                    draggableId={el.id as string}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Table.Cell>
                          {el.title}
                          &nbsp;
                          {el.url && (
                            <a href={el.url} rel="nofollow noopener noreferrer">
                              <Icon name="external alternate"></Icon>
                            </a>
                          )}
                        </Table.Cell>
                        <Table.Cell>{el.status}</Table.Cell>
                        <Table.Cell>
                          {props.tagTodoLinks
                            .filter(
                              (tagTodoLink) => tagTodoLink.todoId === el.id
                            )
                            .map((tagTodoLink) =>
                              props.tags.find(
                                (el) => el.id === tagTodoLink.tagId
                              )
                            )
                            .map((tag) => {
                              return (
                                tag && (
                                  <Label tag key={tag.id}>
                                    {tag.name}
                                  </Label>
                                )
                              );
                            })}
                        </Table.Cell>
                        <Table.Cell>{dateToDateStr(el.dueDate)}</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Icon name="arrows alternate vertical" />

                          <TodoEditDialog
                            initialTodo={el}
                            trigger={<Icon name="edit" />}
                          />
                          <TodoDeleteDialog
                            todo={el}
                            trigger={<Icon name="trash" color={"red"} />}
                          />
                        </Table.Cell>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Table.Body>

              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan="4" textAlign="right">
                    {props.lightweight || (
                      <TodoEditDialog
                        trigger={
                          <Button
                            icon
                            labelPosition="left"
                            primary
                            size="small"
                          >
                            <Icon name="add" />
                            <Trans i18nKey="todo.table.action.add" />
                          </Button>
                        }
                      />
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const SimpleTodoList = () => {
  const { tagId } = useParams();

  const { tags, tagTodoLinks, todos, todosLoading } = useSelector<
    RootState,
    ReduxProps
  >((state) => {
    return {
      tags: state.tag.tags,
      tagTodoLinks: state.tagTodoLink.tagTodoLinks,
      todos: state.todo.todos,
      todosLoading: state.todo.loading,
    };
  });

  return (
    <TodoList
      tagId={tagId}
      tags={tags}
      tagTodoLinks={tagTodoLinks}
      todos={todos}
      todosLoading={todosLoading}
      lightweight={false}
    ></TodoList>
  );
};

export default SimpleTodoList;
