import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dimmer,
  Message,
  Loader,
  Form,
  Modal,
} from "semantic-ui-react";
import { Trans, useTranslation } from "react-i18next";
import { Tag, TagPatch } from "../../backend/axios/api";
import { TagService } from "../../backend/axios/connector";
import { AppNotificationType } from "../../store/notification/reducer";
import NotificationDispatcher from "../../store/notification/dispatcher";
import TagDispatcher from "../../store/tag/dispatcher";

interface Props {
  initialTag?: Tag;
  trigger: React.ReactNode;
}

const TagEditDialog = (props: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const notificationDispatcher = new NotificationDispatcher(dispatch);
  const tagDispatcher = new TagDispatcher(dispatch);

  const [open, setOpen] = React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);
  const [hasSubmitError, setHasSubmitError] = React.useState(false);

  const [formData, setFormData] = React.useState<TagPatch>({});
  React.useEffect(() => {
    setFormData(props.initialTag ? props.initialTag : {});
  }, [props.initialTag]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let adjustedValue: any = value;
    if (target.type === "date") {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        adjustedValue = undefined;
      } else {
        adjustedValue = date;
      }
    } else if (target.type === "number") {
      adjustedValue = Number(value);
    }

    setFormData({
      ...formData,
      [name]: adjustedValue,
    });
  };

  const onSubmit = () => {
    setSubmitting(true);

    const tag: TagPatch = {
      ...formData,
    };

    const axiosAction = props.initialTag?.id
      ? TagService.updateTag(props.initialTag.id, tag)
      : TagService.addTag(tag);

    axiosAction
      .then((response) => {
        setOpen(false);
        setHasSubmitError(false);

        tagDispatcher.loadTags();
      })
      .catch((error) => {
        setHasSubmitError(true);

        notificationDispatcher.addNotification({
          type: AppNotificationType.ERROR,
          message:
            error?.response?.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.task.save",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      trigger={props.trigger}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeIcon={true}
    >
      <Modal.Header>
        {props.initialTag?.id ? (
          <Trans i18nKey="tag.dialog.edit.title" />
        ) : (
          <Trans i18nKey="tag.dialog.add.title" />
        )}
      </Modal.Header>
      <Modal.Content>
        <Dimmer active={submitting}>
          <Loader>
            <Trans i18nKey="loading" />
          </Loader>
        </Dimmer>

        <Form>
          <Form.Input
            label="Name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </Form>

        {hasSubmitError && (
          <Message
            error
            header={t("axios.error.server")}
            content={
              props.initialTag?.id
                ? t("axios.error.task.update")
                : t("axios.error.task.add")
            }
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          {props.initialTag?.id ? (
            <Trans i18nKey="tag.dialog.edit.button.cancel" />
          ) : (
            <Trans i18nKey="tag.dialog.add.button.cancel" />
          )}
        </Button>
        <Button
          onClick={onSubmit}
          positive
          labelPosition="right"
          icon="checkmark"
          content={
            props.initialTag?.id ? (
              <Trans i18nKey="tag.dialog.edit.button.submit" />
            ) : (
              <Trans i18nKey="tag.dialog.add.button.submit" />
            )
          }
        />
      </Modal.Actions>
    </Modal>
  );
};

export default TagEditDialog;
