import { all } from "redux-saga/effects";

import { configSagas } from "./config/saga";
import { tagTodoLinkSagas } from "./tagTodoLink/saga";
import { tagSagas } from "./tag/saga";
import { todoSagas } from "./todo/saga";
import { searchSagas } from "./search/saga";

const rootSaga = function* () {
  yield all([
    configSagas(),
    searchSagas(),
    tagSagas(),
    tagTodoLinkSagas(),
    todoSagas(),
  ]);
};

export default rootSaga;
