import { NotificationDispatchAction, NotificationActionType, AppNotificationType } from './../notification/reducer';
import { TagService } from "../../backend/axios/connector";
import { TagActionType, TagDispatchAction } from "./reducer";
import {
  all,
  put,
  takeLatest,
  cancelled,
  cancel,
} from "redux-saga/effects"

export const getCurrentTagsSaga = function* (action: TagDispatchAction) {
  yield put({ type: TagActionType.GET_CURRENT_TAGS_REQUEST, info: {} });
  try {
    const result = yield TagService.getAllTags();
    if (result && result.status === 200) {
      yield put<TagDispatchAction>({
        type: TagActionType.GET_CURRENT_TAGS_SUCCESS,
        payload: { tags: result.data },
      });
    } else {
      yield put<TagDispatchAction>({
        type: TagActionType.GET_CURRENT_TAGS_FAILURE,
        payload: {},
      });
      yield put<NotificationDispatchAction>({
        type: NotificationActionType.NOTIFICATION_ADD,
        payload: {
          type: AppNotificationType.ERROR,
          message:
            result.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.tag.load",
        },
      });
      yield cancel();
    }
  } catch (error) {
    yield put<TagDispatchAction>({
      type: TagActionType.GET_CURRENT_TAGS_FAILURE,
      payload: {},
    });
  } finally {
    if (yield cancelled()) {
    }
  }
};

export const tagSagas = function* () {
  yield all([
    takeLatest(TagActionType.GET_CURRENT_TAGS_TRIGGER, getCurrentTagsSaga),
  ]);
};
