import React from "react";
import { Container, Message } from "semantic-ui-react";
import { RootState } from "../../store/reducer";
import { useSelector } from "react-redux";

export const checkIsLogin = (token: string | undefined) => {
  if (token !== undefined) {
    return true;
  }
  return false;
};

interface Props {
  check: (auth: string | undefined) => boolean;
  children: React.ReactElement;
}
interface StateProps {
  token: string | undefined;
}
export const CheckAuthentication: React.FC<Props> = (props: Props) => {
  const { token } = useSelector<RootState, StateProps>((state) => {
    return {
      token: state.user.token,
    };
  });

  return props.check(token) ? (
    props.children
  ) : (
    <Container text>
      <Message
        negative
        icon="ban"
        header="You have no permission to access this resource"
        content={
          token === undefined ? (
            <>
              <a href="/#/login">Please login</a>
            </>
          ) : (
            <>
              If you have another account, try to{" "}
              <a href="/#/logout">logout first</a> and{" "}
              <a href="/#/login">login with the other account</a>
            </>
          )
        }
      />
    </Container>
  );
};

export default CheckAuthentication;
