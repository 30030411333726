import { Dispatch } from "redux";

import { TagTodoLinkDispatchAction, TagTodoLinkActionType } from "./reducer";

export class TagTodoLinkDispatcher {
  private readonly dispatch: Dispatch<TagTodoLinkDispatchAction>;

  constructor(dispatch: Dispatch<TagTodoLinkDispatchAction>) {
    this.dispatch = dispatch;
  }
  loadTagTodoLinks = () =>
    this.dispatch({
      type: TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_TRIGGER,
      payload: {},
    });
}

export default TagTodoLinkDispatcher;
