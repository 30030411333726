import { setApiKey } from "../../../../configuration/axios";
import {
  storageItemPut,
  storageItemDelete,
  storageItemGet,
} from "../../../util/localStorage";
import { Action, Reducer } from "redux";

export interface UserState {
  email: string;
  name: string;
  token?: string;
}

export const initialState: UserState = {
  email: "",
  name: "",
  token: storageItemGet("token", undefined),
};
setApiKey(initialState.token);

export interface UserDispatchAction extends Action<UserActionType> {
  payload: Partial<UserState>;
}

export enum UserActionType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

export const userReducer: Reducer<UserState, UserDispatchAction> = (
  state = initialState,
  action
): UserState => {
  switch (action.type) {
    case UserActionType.LOGIN:
      if (action.payload.token) {
        storageItemPut("token", action.payload.token);
        setApiKey(action.payload.token);
      } else {
        storageItemDelete("token");
      }
      return {
        ...state,
        email: action.payload.email || "",
        name: action.payload.name || "",
        token: action.payload.token,
      };
    case UserActionType.LOGOUT:
      setApiKey(undefined);
      storageItemDelete("token");
      return { ...state, email: "", name: "", token: undefined };
    default:
      return state;
  }
};
