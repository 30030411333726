import { NotificationDispatchAction, NotificationActionType, AppNotificationType } from './../notification/reducer';
import { TagTodoLinkActionType, TagTodoLinkDispatchAction } from './reducer';
import {
  all,
  put,
  takeLatest,
  cancelled,
  cancel,
} from "redux-saga/effects"
import { TagTodoLinkService } from './../../backend/axios/connector';

export const getCurrentTagsSaga = function* (action: TagTodoLinkDispatchAction) {
  yield put({ type: TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_REQUEST, info: {} });
  try {
    const result = yield TagTodoLinkService.getLinks();
    if (result && result.status === 200) {
      yield put<TagTodoLinkDispatchAction>({
        type: TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_SUCCESS,
        payload: { tagTodoLinks: result.data },
      });
    } else {
      yield put<TagTodoLinkDispatchAction>({
        type: TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_FAILURE,
        payload: {},
      });
      yield put<NotificationDispatchAction>({
        type: NotificationActionType.NOTIFICATION_ADD,
        payload: {
          type: AppNotificationType.ERROR,
          message:
            result.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.tagTodoLink.load",
        },
      });
      yield cancel();
    }
  } catch (error) {
    yield put<TagTodoLinkDispatchAction>({
      type: TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_FAILURE,
      payload: {},
    });
  } finally {
    if (yield cancelled()) {
    }
  }
};

export const tagTodoLinkSagas = function* () {
  yield all([
    takeLatest(TagTodoLinkActionType.GET_CURRENT_TAG_TODO_LINKS_TRIGGER, getCurrentTagsSaga),
  ]);
};
