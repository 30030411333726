import { combineReducers } from "redux";
import { NotificationState, notificationReducer } from "./notification/reducer";
import { UserState, userReducer } from "./user/reducer";
import { TodoState, todoReducer } from "./todo/reducer";
import { TimerState, timerReducer } from "./timer/reducer";
import { TagTodoLinkState, tagTodoLinkReducer } from "./tagTodoLink/reducer";
import { TagState, tagReducer } from "./tag/reducer";
import { SearchState, searchReducer } from "./search/reducer";
import { ConfigReducer, ConfigState } from "./config/reducer";

export interface RootState {
  config: ConfigState;
  notification: NotificationState;
  search: SearchState;
  tag: TagState;
  tagTodoLink: TagTodoLinkState;
  timer: TimerState;
  todo: TodoState;
  user: UserState;
}
const rootReducer = combineReducers({
  config: ConfigReducer,
  notification: notificationReducer,
  search: searchReducer,
  tag: tagReducer,
  tagTodoLink: tagTodoLinkReducer,
  timer: timerReducer,
  todo: todoReducer,
  user: userReducer,
});

export default rootReducer;
