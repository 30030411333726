import React from "react";
import { Header, List } from "semantic-ui-react";

const LicensePage = () => {
  return (
    <>
      <Header as="h2">Licenses</Header>
      Used software in this product
      <List bulleted>
        <List.Item
          as="a"
          href="https://notificationsounds.com/notification-sounds/eventually-590"
        >
          Notification sound
        </List.Item>
      </List>
    </>
  );
};

export default LicensePage;
