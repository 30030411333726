import { Dispatch } from "redux";

import { ConfigDispatchAction, ConfigActionType } from "./reducer";

export class ConfigDispatcher {
  private readonly dispatch: Dispatch<ConfigDispatchAction>;

  constructor(dispatch: Dispatch<ConfigDispatchAction>) {
    this.dispatch = dispatch;
  }

  refresh = () => {
    this.dispatch({
      type: ConfigActionType.GET_CONFIG_TRIGGER,
      payload: {},
    });
  };
}

export default ConfigDispatcher;
