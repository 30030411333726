import { Tag } from "../../backend/axios/api";
import { Action, Reducer } from "redux";

export interface TagState {
  loading: boolean;
  loaded: boolean;
  tags: Tag[];
  error: boolean;
}

export const initialState: TagState = {
  loading: false,
  loaded: false,
  tags: [],
  error: false,
};

export enum TagActionType {
  GET_CURRENT_TAGS_TRIGGER = "GET_CURRENT_TAGS_TRIGGER",
  GET_CURRENT_TAGS_REQUEST = "GET_CURRENT_TAGS_REQUEST",
  GET_CURRENT_TAGS_SUCCESS = "GET_CURRENT_TAGS_SUCCESS",
  GET_CURRENT_TAGS_FAILURE = "GET_CURRENT_TAGS_FAILURE",
  TAGS_CLEAR_ERROR_MESSAGE = "TAGS_CLEAR_ERROR_MESSAGE",
}
export interface TagDispatchAction extends Action<TagActionType> {
  payload: Partial<TagState>;
}

export const tagReducer: Reducer<TagState, TagDispatchAction> = (
  state = initialState,
  action
): TagState => {
  switch (action.type) {
    case TagActionType.GET_CURRENT_TAGS_REQUEST:
      return { ...state, loading: true };

    case TagActionType.GET_CURRENT_TAGS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        tags: action.payload.tags ? action.payload.tags : state.tags,
      };
    case TagActionType.GET_CURRENT_TAGS_FAILURE:
      return { ...state, error: true, loading: false };
    case TagActionType.TAGS_CLEAR_ERROR_MESSAGE:
      return { ...state, error: false };

    default:
      return state;
  }
};
