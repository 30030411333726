
const notificationSupported = (): boolean => {
  return "Notification" in window;
};
const notificationGranted = (): boolean => {
  return Notification.permission === "granted";
};

export function useNotification() {
  return function(message: string) {
    if (notificationSupported()) {
      let promise = Promise.resolve<NotificationPermission>("granted");
      if (!notificationGranted()) {
        promise = Notification.requestPermission();
      }
      promise.then(function(permission) {
        if (notificationGranted()) {
          new Notification(message);
        }
      });
    }
  };
}
