import React, { useEffect } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Container, Grid, Message, Icon } from "semantic-ui-react";

import { store } from "./store/store";
import AppMenu from "./page/menu/menu";
import MenuSidebar from "./page/menu/sidebar";
import AppFooter from "./page/menu/footer";
import LoginPage from "./page/auth/login";
import LoggedInPage from "./page/auth/loggedIn";
import LogoutPage from "./page/auth/logout";
import {
  CheckAuthentication,
  checkIsLogin,
} from "./page/auth/checkAuthentication";
import TimerPage from "./page/timer/timer";
import { SimpleTagList } from "./page/tag/tagList";
import PrivacyPage from "./page/privacy";
import AboutPage from "./page/about";
import LicensePage from "./page/license";
import TermsPage from "./page/terms";
import SearchPage from "./page/search";
import ConfigDispatcher from "./store/config/dispatcher";
import TodoDispatcher from "./store/todo/dispatcher";
import TagDispatcher from "./store/tag/dispatcher";
import TagTodoLinkDispatcher from "./store/tagTodoLink/dispatcher";
import TimerDispatcher from "./store/timer/dispatcher";
import SimpleTodoList from "./page/todo/todoList";
import UserDispatcher from "./store/user/dispatcher";

const App = () => {
  const dispatch = store.dispatch;
  const configDispatch = new ConfigDispatcher(dispatch);
  const tagDispatch = new TagDispatcher(dispatch);
  const tagTodoLinkDispatch = new TagTodoLinkDispatcher(dispatch);
  const timerDispatch = new TimerDispatcher(dispatch);
  const todoDispatch = new TodoDispatcher(dispatch);
  const userDispatch = new UserDispatcher(dispatch);

  useEffect(() => {
    if (store.getState().user.token) {
      configDispatch.refresh();
      todoDispatch.loadTodos();
      tagDispatch.loadTags();
      tagTodoLinkDispatch.loadTagTodoLinks();
      userDispatch.refresh();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(() => {
      timerDispatch.tick();
    }, 1000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Switch>
      <Route path="/login" exact={true}>
        <LoginPage />
      </Route>
      <Route path="/login/callback" exact={true}>
        <LoggedInPage />
      </Route>
      <Route path="/logout" exact={true}>
        <LogoutPage />
      </Route>

      <Route path="/timer" exact={true}>
        <TimerPage />
      </Route>

      <Route path="/tag" exact={true}>
        <CheckAuthentication check={checkIsLogin}>
          <SimpleTagList />
        </CheckAuthentication>
      </Route>

      <Route path={["/todo", "/tag/:tagId", "/", "/home"]} exact={true}>
        <CheckAuthentication check={checkIsLogin}>
          <SimpleTodoList />
        </CheckAuthentication>
      </Route>

      <Route path="/search/:searchTerm" exact={true}>
        <CheckAuthentication check={checkIsLogin}>
          <SearchPage />
        </CheckAuthentication>
      </Route>

      <Route path="/about" exact={true}>
        <AboutPage />
      </Route>
      <Route path="/license" exact={true}>
        <LicensePage />
      </Route>
      <Route path="/privacy" exact={true}>
        <PrivacyPage />
      </Route>
      <Route path="/terms" exact={true}>
        <TermsPage />
      </Route>

      <Route>
        <Message negative icon>
          <Icon name="compass outline" loading />
          Hm, this page does not seem to exist
        </Message>
      </Route>
    </Switch>
  );

  return (
    <Provider store={store}>
      <HashRouter>
        <AppMenu />

        <Grid style={{ marginTop: "3em", flex: "1 1 auto" }}>
          <Grid.Row only={"mobile tablet"}>
            <Grid.Column width={16}>
              <Container textAlign={"center"}>
                <MenuSidebar vertical={false} />
              </Container>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={2} only={"computer"} floated="left">
              <MenuSidebar />
            </Grid.Column>

            <Grid.Column computer={12} width={16}>
              <Container>
                {process.env.NODE_ENV === "development" && (
                  <Message negative>
                    You are running this application in <b>development</b> mode.
                  </Message>
                )}
              </Container>

              <Container>{content}</Container>
            </Grid.Column>

            <Grid.Column
              width={2}
              only={"computer"}
              floated="right"
            ></Grid.Column>
          </Grid.Row>
        </Grid>

        <AppFooter />
      </HashRouter>
    </Provider>
  );
};

export default App;
