import { useLocation } from "react-router-dom";
import queryString from "query-string";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getPathOfLocationHash(hash: string) {
  if (hash.indexOf("?") !== -1) {
    return hash.substring(0, hash.indexOf("?"));
  }
  return hash;
}

export function getQueryOfLocationHash(hash: string) {
  if (hash.indexOf("?") !== -1) {
    return hash.substring(hash.indexOf("?"));
  }
  return "";
}

export function updateLocationHashPath(key: string, value: any) {
  const path = getPathOfLocationHash(window.location.hash);
  const search = queryString.parse(
    getQueryOfLocationHash(window.location.hash)
  ) as any;
  if (0 < value.length) {
    search[key] = value;
  } else {
    delete search[key];
  }
  window.location.hash =
    path +
    (Object.keys(search).length === 0
      ? ""
      : "?" + queryString.stringify(search));
}
