// tslint:disable
/**
 * Todo App
 * Api description for TodoApp
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: todo-admin@timonback.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface ApiLoginKey
 */
export interface ApiLoginKey {
  /**
   *
   * @type {string}
   * @memberof ApiLoginKey
   */
  apiKey?: string;
}
/**
 *
 * @export
 * @interface ApiResponseError
 */
export interface ApiResponseError {
  /**
   *
   * @type {number}
   * @memberof ApiResponseError
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ApiResponseError
   */
  message?: string;
}
/**
 *
 * @export
 * @interface Config
 */
export interface Config {
  /**
   * Is the service currently up and running
   * @type {boolean}
   * @memberof Config
   */
  running?: boolean;
  /**
   *
   * @type {ConfigFeatures}
   * @memberof Config
   */
  features?: ConfigFeatures;
}
/**
 *
 * @export
 * @interface ConfigFeatures
 */
export interface ConfigFeatures {
  /**
   * Is search currently possible
   * @type {boolean}
   * @memberof ConfigFeatures
   */
  search?: boolean;
}
/**
 *
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
  /**
   * Search for this search term
   * @type {string}
   * @memberof SearchRequest
   */
  term: string;
}
/**
 *
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
  /**
   *
   * @type {Array<Todo>}
   * @memberof SearchResult
   */
  todos: Array<Todo>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof SearchResult
   */
  tags: Array<Tag>;
}
/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Tag
   */
  priority?: number;
}
/**
 *
 * @export
 * @interface TagChangePosition
 */
export interface TagChangePosition {
  /**
   *
   * @type {Id}
   * @memberof TagChangePosition
   */
  beforeTag?: string;
}
/**
 *
 * @export
 * @interface TagPatch
 */
export interface TagPatch {
  /**
   *
   * @type {Name}
   * @memberof TagPatch
   */
  name?: string;
  /**
   *
   * @type {Priority}
   * @memberof TagPatch
   */
  priority?: number;
}
/**
 *
 * @export
 * @interface TagTodoLink
 */
export interface TagTodoLink {
  /**
   *
   * @type {string}
   * @memberof TagTodoLink
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TagTodoLink
   */
  todoId: Todo["id"];
  /**
   *
   * @type {string}
   * @memberof TagTodoLink
   */
  tagId: Tag["id"];
  /**
   * Internal field used for positioning
   * @type {number}
   * @memberof TagTodoLink
   */
  priority: number;
}
/**
 *
 * @export
 * @interface TagTodoLinkPatch
 */
export interface TagTodoLinkPatch {
  /**
   *
   * @type {TodoId}
   * @memberof TagTodoLinkPatch
   */
  todoId?: Todo["id"];
  /**
   *
   * @type {TagId}
   * @memberof TagTodoLinkPatch
   */
  tagId?: Tag["id"];
  /**
   *
   * @type {Priority}
   * @memberof TagTodoLinkPatch
   */
  priority?: number;
}
/**
 *
 * @export
 * @interface Todo
 */
export interface Todo {
  /**
   *
   * @type {string}
   * @memberof Todo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Todo
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Todo
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Todo
   */
  url?: string;
  /**
   * current status
   * @type {string}
   * @memberof Todo
   */
  status?: TodoStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof Todo
   */
  dueDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Todo
   */
  createDate?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum TodoStatusEnum {
  Open = "open",
  Scheduled = "scheduled",
  Completed = "completed",
  Deleted = "deleted",
}
export type TodoStatusEnumKey = keyof typeof TodoStatusEnum;

/**
 *
 * @export
 * @interface TodoChangePosition
 */
export interface TodoChangePosition {
  /**
   *
   * @type {Id}
   * @memberof TodoChangePosition
   */
  beforeTodo?: Todo["id"];
}
/**
 *
 * @export
 * @interface TodoPatch
 */
export interface TodoPatch {
  /**
   *
   * @type {Title}
   * @memberof TodoPatch
   */
  title?: string;
  /**
   *
   * @type {Title}
   * @memberof TodoPatch
   */
  description?: string;
  /**
   *
   * @type {Url}
   * @memberof TodoPatch
   */
  url?: string;
  /**
   *
   * @type {Status}
   * @memberof TodoPatch
   */
  status?: TodoStatusEnum;
  /**
   *
   * @type {DueDate}
   * @memberof TodoPatch
   */
  dueDate?: Todo["dueDate"];
  /**
   *
   * @type {CreateDate}
   * @memberof TodoPatch
   */
  createDate?: Todo["createDate"];
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   * User Status
   * @type {number}
   * @memberof User
   */
  userStatus?: number;
  /**
   * Generated, active API Keys
   * @type {Array<UserApiKeys>}
   * @memberof User
   */
  apiKeys?: Array<UserApiKeys>;
}
/**
 *
 * @export
 * @interface UserApiKeys
 */
export interface UserApiKeys {
  /**
   *
   * @type {string}
   * @memberof UserApiKeys
   */
  name?: string;
  /**
   *
   * @type {Date}
   * @memberof UserApiKeys
   */
  createDate?: Date;
  /**
   *
   * @type {ApiKey}
   * @memberof UserApiKeys
   */
  key?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Logs user in using an external identity service
     * @param {'google'} provider The name of the used identity provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthLogin(provider: "google", options: any = {}): RequestArgs {
      // verify required parameter 'provider' is not null or undefined
      if (provider === null || provider === undefined) {
        throw new RequiredError(
          "provider",
          "Required parameter provider was null or undefined when calling oAuthLogin."
        );
      }
      const localVarPath = `/auth/{provider}/login`.replace(
        `{${"provider"}}`,
        encodeURIComponent(String(provider))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logs out current logged in user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthLogout(options: any = {}): RequestArgs {
      const localVarPath = `/auth/logout`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Logs user in using an external identity service
     * @param {'google'} provider The name of the used identity provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthLogin(
      provider: "google",
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiLoginKey> {
      const localVarAxiosArgs = AuthApiAxiosParamCreator(
        configuration
      ).oAuthLogin(provider, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Logs out current logged in user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthLogout(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs =
        AuthApiAxiosParamCreator(configuration).oAuthLogout(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Logs user in using an external identity service
     * @param {'google'} provider The name of the used identity provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthLogin(provider: "google", options?: any): AxiosPromise<ApiLoginKey> {
      return AuthApiFp(configuration).oAuthLogin(provider, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @summary Logs out current logged in user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthLogout(options?: any): AxiosPromise<void> {
      return AuthApiFp(configuration).oAuthLogout(options)(axios, basePath);
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary Logs user in using an external identity service
   * @param {'google'} provider The name of the used identity provider
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public oAuthLogin(provider: "google", options?: any) {
    return AuthApiFp(this.configuration).oAuthLogin(provider, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Logs out current logged in user session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public oAuthLogout(options?: any) {
    return AuthApiFp(this.configuration).oAuthLogout(options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Get information about application state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfig(options: any = {}): RequestArgs {
      const localVarPath = `/config`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get information about application state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfig(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Config> {
      const localVarAxiosArgs =
        ConfigApiAxiosParamCreator(configuration).getConfig(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Get information about application state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfig(options?: any): AxiosPromise<Config> {
      return ConfigApiFp(configuration).getConfig(options)(axios, basePath);
    },
  };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
  /**
   * Get information about application state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigApi
   */
  public getConfig(options?: any) {
    return ConfigApiFp(this.configuration).getConfig(options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * LinkApi - axios parameter creator
 * @export
 */
export const LinkApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Link a {todoId} within a tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {TagTodoLinkPatch} [tagTodoLinkPatch] If left empty, the todo has the lowest priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      tagTodoLinkPatch?: TagTodoLinkPatch,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling addLinkTagWithTodo."
        );
      }
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling addLinkTagWithTodo."
        );
      }
      const localVarPath = `/tag/{tagId}/link/{todoId}`
        .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)))
        .replace(`{${"todoId"}}`, encodeURIComponent(String(todoId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof tagTodoLinkPatch !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tagTodoLinkPatch !== undefined ? tagTodoLinkPatch : {})
        : tagTodoLinkPatch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove {todoId} from tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling deleteLinkTagWithTodo."
        );
      }
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling deleteLinkTagWithTodo."
        );
      }
      const localVarPath = `/tag/{tagId}/link/{todoId}`
        .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)))
        .replace(`{${"todoId"}}`, encodeURIComponent(String(todoId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the link
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinkForTagToTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling getLinkForTagToTodo."
        );
      }
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling getLinkForTagToTodo."
        );
      }
      const localVarPath = `/tag/{tagId}/link/{todoId}`
        .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)))
        .replace(`{${"todoId"}}`, encodeURIComponent(String(todoId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinks(options: any = {}): RequestArgs {
      const localVarPath = `/link`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all links for a tag
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinksForTag(tagId: Tag["id"], options: any = {}): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling getLinksForTag."
        );
      }
      const localVarPath = `/tag/{tagId}/link`.replace(
        `{${"tagId"}}`,
        encodeURIComponent(String(tagId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Link a {todoId} within a tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {TagTodoLinkPatch} [tagTodoLinkPatch] Update the link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      tagTodoLinkPatch?: TagTodoLinkPatch,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling patchLinkTagWithTodo."
        );
      }
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling patchLinkTagWithTodo."
        );
      }
      const localVarPath = `/tag/{tagId}/link/{todoId}`
        .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)))
        .replace(`{${"todoId"}}`, encodeURIComponent(String(todoId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof tagTodoLinkPatch !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tagTodoLinkPatch !== undefined ? tagTodoLinkPatch : {})
        : tagTodoLinkPatch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LinkApi - functional programming interface
 * @export
 */
export const LinkApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Link a {todoId} within a tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {TagTodoLinkPatch} [tagTodoLinkPatch] If left empty, the todo has the lowest priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      tagTodoLinkPatch?: TagTodoLinkPatch,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag> {
      const localVarAxiosArgs = LinkApiAxiosParamCreator(
        configuration
      ).addLinkTagWithTodo(tagId, todoId, tagTodoLinkPatch, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Remove {todoId} from tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LinkApiAxiosParamCreator(
        configuration
      ).deleteLinkTagWithTodo(tagId, todoId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get the link
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinkForTagToTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagTodoLink> {
      const localVarAxiosArgs = LinkApiAxiosParamCreator(
        configuration
      ).getLinkForTagToTodo(tagId, todoId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get all links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinks(
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<Array<TagTodoLink>> {
      const localVarAxiosArgs =
        LinkApiAxiosParamCreator(configuration).getLinks(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get all links for a tag
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinksForTag(
      tagId: Tag["id"],
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<Array<TagTodoLink>> {
      const localVarAxiosArgs = LinkApiAxiosParamCreator(
        configuration
      ).getLinksForTag(tagId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Link a {todoId} within a tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {TagTodoLinkPatch} [tagTodoLinkPatch] Update the link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      tagTodoLinkPatch?: TagTodoLinkPatch,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag> {
      const localVarAxiosArgs = LinkApiAxiosParamCreator(
        configuration
      ).patchLinkTagWithTodo(tagId, todoId, tagTodoLinkPatch, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LinkApi - factory interface
 * @export
 */
export const LinkApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Link a {todoId} within a tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {TagTodoLinkPatch} [tagTodoLinkPatch] If left empty, the todo has the lowest priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      tagTodoLinkPatch?: TagTodoLinkPatch,
      options?: any
    ): AxiosPromise<Tag> {
      return LinkApiFp(configuration).addLinkTagWithTodo(
        tagId,
        todoId,
        tagTodoLinkPatch,
        options
      )(axios, basePath);
    },
    /**
     * Remove {todoId} from tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      options?: any
    ): AxiosPromise<void> {
      return LinkApiFp(configuration).deleteLinkTagWithTodo(
        tagId,
        todoId,
        options
      )(axios, basePath);
    },
    /**
     * Get the link
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinkForTagToTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      options?: any
    ): AxiosPromise<TagTodoLink> {
      return LinkApiFp(configuration).getLinkForTagToTodo(
        tagId,
        todoId,
        options
      )(axios, basePath);
    },
    /**
     * Get all links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinks(options?: any): AxiosPromise<Array<TagTodoLink>> {
      return LinkApiFp(configuration).getLinks(options)(axios, basePath);
    },
    /**
     * Get all links for a tag
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLinksForTag(
      tagId: Tag["id"],
      options?: any
    ): AxiosPromise<Array<TagTodoLink>> {
      return LinkApiFp(configuration).getLinksForTag(tagId, options)(
        axios,
        basePath
      );
    },
    /**
     * Link a {todoId} within a tag {tagId}
     * @param {Id} tagId The tag id
     * @param {Id} todoId The todo id
     * @param {TagTodoLinkPatch} [tagTodoLinkPatch] Update the link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchLinkTagWithTodo(
      tagId: Tag["id"],
      todoId: Todo["id"],
      tagTodoLinkPatch?: TagTodoLinkPatch,
      options?: any
    ): AxiosPromise<Tag> {
      return LinkApiFp(configuration).patchLinkTagWithTodo(
        tagId,
        todoId,
        tagTodoLinkPatch,
        options
      )(axios, basePath);
    },
  };
};

/**
 * LinkApi - object-oriented interface
 * @export
 * @class LinkApi
 * @extends {BaseAPI}
 */
export class LinkApi extends BaseAPI {
  /**
   * Link a {todoId} within a tag {tagId}
   * @param {Id} tagId The tag id
   * @param {Id} todoId The todo id
   * @param {TagTodoLinkPatch} [tagTodoLinkPatch] If left empty, the todo has the lowest priority
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkApi
   */
  public addLinkTagWithTodo(
    tagId: Tag["id"],
    todoId: Todo["id"],
    tagTodoLinkPatch?: TagTodoLinkPatch,
    options?: any
  ) {
    return LinkApiFp(this.configuration).addLinkTagWithTodo(
      tagId,
      todoId,
      tagTodoLinkPatch,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Remove {todoId} from tag {tagId}
   * @param {Id} tagId The tag id
   * @param {Id} todoId The todo id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkApi
   */
  public deleteLinkTagWithTodo(
    tagId: Tag["id"],
    todoId: Todo["id"],
    options?: any
  ) {
    return LinkApiFp(this.configuration).deleteLinkTagWithTodo(
      tagId,
      todoId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get the link
   * @param {Id} tagId The tag id
   * @param {Id} todoId The todo id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkApi
   */
  public getLinkForTagToTodo(
    tagId: Tag["id"],
    todoId: Todo["id"],
    options?: any
  ) {
    return LinkApiFp(this.configuration).getLinkForTagToTodo(
      tagId,
      todoId,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Get all links
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkApi
   */
  public getLinks(options?: any) {
    return LinkApiFp(this.configuration).getLinks(options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Get all links for a tag
   * @param {Id} tagId The tag id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkApi
   */
  public getLinksForTag(tagId: Tag["id"], options?: any) {
    return LinkApiFp(this.configuration).getLinksForTag(tagId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Link a {todoId} within a tag {tagId}
   * @param {Id} tagId The tag id
   * @param {Id} todoId The todo id
   * @param {TagTodoLinkPatch} [tagTodoLinkPatch] Update the link
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkApi
   */
  public patchLinkTagWithTodo(
    tagId: Tag["id"],
    todoId: Todo["id"],
    tagTodoLinkPatch?: TagTodoLinkPatch,
    options?: any
  ) {
    return LinkApiFp(this.configuration).patchLinkTagWithTodo(
      tagId,
      todoId,
      tagTodoLinkPatch,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Search for todos and tags
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(searchRequest?: SearchRequest, options: any = {}): RequestArgs {
      const localVarPath = `/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof searchRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchRequest !== undefined ? searchRequest : {})
        : searchRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Search for todos and tags
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      searchRequest?: SearchRequest,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<SearchResult> {
      const localVarAxiosArgs = SearchApiAxiosParamCreator(
        configuration
      ).search(searchRequest, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Search for todos and tags
     * @param {SearchRequest} [searchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(
      searchRequest?: SearchRequest,
      options?: any
    ): AxiosPromise<SearchResult> {
      return SearchApiFp(configuration).search(searchRequest, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   * Search for todos and tags
   * @param {SearchRequest} [searchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public search(searchRequest?: SearchRequest, options?: any) {
    return SearchApiFp(this.configuration).search(searchRequest, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Add a new tag
     * @param {TagPatch} tagPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTag(tagPatch: TagPatch, options: any = {}): RequestArgs {
      // verify required parameter 'tagPatch' is not null or undefined
      if (tagPatch === null || tagPatch === undefined) {
        throw new RequiredError(
          "tagPatch",
          "Required parameter tagPatch was null or undefined when calling addTag."
        );
      }
      const localVarPath = `/tag`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof tagPatch !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tagPatch !== undefined ? tagPatch : {})
        : tagPatch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Change the position of the tag {tagId}
     * @param {Id} tagId The tag id
     * @param {TagChangePosition} [tagChangePosition] If left empty, the tag is moved to the end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTagPosition(
      tagId: Tag["id"],
      tagChangePosition?: TagChangePosition,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling changeTagPosition."
        );
      }
      const localVarPath = `/tag/{tagId}/position`.replace(
        `{${"tagId"}}`,
        encodeURIComponent(String(tagId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof tagChangePosition !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            tagChangePosition !== undefined ? tagChangePosition : {}
          )
        : tagChangePosition || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a tag
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTag(tagId: Tag["id"], options: any = {}): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling deleteTag."
        );
      }
      const localVarPath = `/tag/{tagId}`.replace(
        `{${"tagId"}}`,
        encodeURIComponent(String(tagId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTags(options: any = {}): RequestArgs {
      const localVarPath = `/tag`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Tag information
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTag(tagId: Tag["id"], options: any = {}): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling getTag."
        );
      }
      const localVarPath = `/tag/{tagId}`.replace(
        `{${"tagId"}}`,
        encodeURIComponent(String(tagId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a tag
     * @param {Id} tagId The tag id
     * @param {TagPatch} [tagPatch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTag(
      tagId: Tag["id"],
      tagPatch?: TagPatch,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          "tagId",
          "Required parameter tagId was null or undefined when calling updateTag."
        );
      }
      const localVarPath = `/tag/{tagId}`.replace(
        `{${"tagId"}}`,
        encodeURIComponent(String(tagId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof tagPatch !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tagPatch !== undefined ? tagPatch : {})
        : tagPatch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Add a new tag
     * @param {TagPatch} tagPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTag(
      tagPatch: TagPatch,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag> {
      const localVarAxiosArgs = TagApiAxiosParamCreator(configuration).addTag(
        tagPatch,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Change the position of the tag {tagId}
     * @param {Id} tagId The tag id
     * @param {TagChangePosition} [tagChangePosition] If left empty, the tag is moved to the end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTagPosition(
      tagId: Tag["id"],
      tagChangePosition?: TagChangePosition,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag> {
      const localVarAxiosArgs = TagApiAxiosParamCreator(
        configuration
      ).changeTagPosition(tagId, tagChangePosition, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete a tag
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTag(
      tagId: Tag["id"],
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TagApiAxiosParamCreator(
        configuration
      ).deleteTag(tagId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get all tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTags(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>> {
      const localVarAxiosArgs =
        TagApiAxiosParamCreator(configuration).getAllTags(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get Tag information
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTag(
      tagId: Tag["id"],
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag> {
      const localVarAxiosArgs = TagApiAxiosParamCreator(configuration).getTag(
        tagId,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updates a tag
     * @param {Id} tagId The tag id
     * @param {TagPatch} [tagPatch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTag(
      tagId: Tag["id"],
      tagPatch?: TagPatch,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag> {
      const localVarAxiosArgs = TagApiAxiosParamCreator(
        configuration
      ).updateTag(tagId, tagPatch, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Add a new tag
     * @param {TagPatch} tagPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTag(tagPatch: TagPatch, options?: any): AxiosPromise<Tag> {
      return TagApiFp(configuration).addTag(tagPatch, options)(axios, basePath);
    },
    /**
     * Change the position of the tag {tagId}
     * @param {Id} tagId The tag id
     * @param {TagChangePosition} [tagChangePosition] If left empty, the tag is moved to the end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTagPosition(
      tagId: Tag["id"],
      tagChangePosition?: TagChangePosition,
      options?: any
    ): AxiosPromise<Tag> {
      return TagApiFp(configuration).changeTagPosition(
        tagId,
        tagChangePosition,
        options
      )(axios, basePath);
    },
    /**
     * Delete a tag
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTag(tagId: Tag["id"], options?: any): AxiosPromise<void> {
      return TagApiFp(configuration).deleteTag(tagId, options)(axios, basePath);
    },
    /**
     * Get all tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTags(options?: any): AxiosPromise<Array<Tag>> {
      return TagApiFp(configuration).getAllTags(options)(axios, basePath);
    },
    /**
     * Get Tag information
     * @param {Id} tagId The tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTag(tagId: Tag["id"], options?: any): AxiosPromise<Tag> {
      return TagApiFp(configuration).getTag(tagId, options)(axios, basePath);
    },
    /**
     *
     * @summary Updates a tag
     * @param {Id} tagId The tag id
     * @param {TagPatch} [tagPatch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTag(
      tagId: Tag["id"],
      tagPatch?: TagPatch,
      options?: any
    ): AxiosPromise<Tag> {
      return TagApiFp(configuration).updateTag(
        tagId,
        tagPatch,
        options
      )(axios, basePath);
    },
  };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
  /**
   * Add a new tag
   * @param {TagPatch} tagPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public addTag(tagPatch: TagPatch, options?: any) {
    return TagApiFp(this.configuration).addTag(tagPatch, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Change the position of the tag {tagId}
   * @param {Id} tagId The tag id
   * @param {TagChangePosition} [tagChangePosition] If left empty, the tag is moved to the end
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public changeTagPosition(
    tagId: Tag["id"],
    tagChangePosition?: TagChangePosition,
    options?: any
  ) {
    return TagApiFp(this.configuration).changeTagPosition(
      tagId,
      tagChangePosition,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Delete a tag
   * @param {Id} tagId The tag id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public deleteTag(tagId: Tag["id"], options?: any) {
    return TagApiFp(this.configuration).deleteTag(tagId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Get all tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public getAllTags(options?: any) {
    return TagApiFp(this.configuration).getAllTags(options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Get Tag information
   * @param {Id} tagId The tag id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public getTag(tagId: Tag["id"], options?: any) {
    return TagApiFp(this.configuration).getTag(tagId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Updates a tag
   * @param {Id} tagId The tag id
   * @param {TagPatch} [tagPatch]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagApi
   */
  public updateTag(tagId: Tag["id"], tagPatch?: TagPatch, options?: any) {
    return TagApiFp(this.configuration).updateTag(
      tagId,
      tagPatch,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * TodoApi - axios parameter creator
 * @export
 */
export const TodoApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a new todo
     * @param {TodoPatch} todoPatch The new todo object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTodo(todoPatch: TodoPatch, options: any = {}): RequestArgs {
      // verify required parameter 'todoPatch' is not null or undefined
      if (todoPatch === null || todoPatch === undefined) {
        throw new RequiredError(
          "todoPatch",
          "Required parameter todoPatch was null or undefined when calling addTodo."
        );
      }
      const localVarPath = `/todo`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof todoPatch !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(todoPatch !== undefined ? todoPatch : {})
        : todoPatch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a single todo
     * @summary Change position of a todo
     * @param {Id} todoId ID of todo
     * @param {TodoChangePosition} [todoChangePosition] If left empty, the tag is moved to the end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTodoPosition(
      todoId: Todo["id"],
      todoChangePosition?: TodoChangePosition,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling changeTodoPosition."
        );
      }
      const localVarPath = `/todo/{todoId}/position`.replace(
        `{${"todoId"}}`,
        encodeURIComponent(String(todoId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof todoChangePosition !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            todoChangePosition !== undefined ? todoChangePosition : {}
          )
        : todoChangePosition || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deletes a todo
     * @param {Id} todoId ID of todo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTodo(todoId: Todo["id"], options: any = {}): RequestArgs {
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling deleteTodo."
        );
      }
      const localVarPath = `/todo/{todoId}`.replace(
        `{${"todoId"}}`,
        encodeURIComponent(String(todoId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all Todos
     * @param {Array<Status>} [status] Status values that need to be considered for filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTodos(
      status?: Array<TodoStatusEnum>,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/todo`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a single todo
     * @summary Find todo by ID
     * @param {Id} todoId ID of todo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTodoById(todoId: Todo["id"], options: any = {}): RequestArgs {
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling getTodoById."
        );
      }
      const localVarPath = `/todo/{todoId}`.replace(
        `{${"todoId"}}`,
        encodeURIComponent(String(todoId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a todo
     * @param {Id} todoId ID of todo
     * @param {TodoPatch} [todoPatch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTodo(
      todoId: Todo["id"],
      todoPatch?: TodoPatch,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'todoId' is not null or undefined
      if (todoId === null || todoId === undefined) {
        throw new RequiredError(
          "todoId",
          "Required parameter todoId was null or undefined when calling updateTodo."
        );
      }
      const localVarPath = `/todo/{todoId}`.replace(
        `{${"todoId"}}`,
        encodeURIComponent(String(todoId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      const needsSerialization =
        typeof todoPatch !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(todoPatch !== undefined ? todoPatch : {})
        : todoPatch || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TodoApi - functional programming interface
 * @export
 */
export const TodoApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add a new todo
     * @param {TodoPatch} todoPatch The new todo object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTodo(
      todoPatch: TodoPatch,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Todo> {
      const localVarAxiosArgs = TodoApiAxiosParamCreator(configuration).addTodo(
        todoPatch,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a single todo
     * @summary Change position of a todo
     * @param {Id} todoId ID of todo
     * @param {TodoChangePosition} [todoChangePosition] If left empty, the tag is moved to the end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTodoPosition(
      todoId: Todo["id"],
      todoChangePosition?: TodoChangePosition,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Todo> {
      const localVarAxiosArgs = TodoApiAxiosParamCreator(
        configuration
      ).changeTodoPosition(todoId, todoChangePosition, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Deletes a todo
     * @param {Id} todoId ID of todo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTodo(
      todoId: Todo["id"],
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TodoApiAxiosParamCreator(
        configuration
      ).deleteTodo(todoId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all Todos
     * @param {Array<Status>} [status] Status values that need to be considered for filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTodos(
      status?: Array<TodoStatusEnum>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Todo>> {
      const localVarAxiosArgs = TodoApiAxiosParamCreator(
        configuration
      ).getAllTodos(status, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a single todo
     * @summary Find todo by ID
     * @param {Id} todoId ID of todo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTodoById(
      todoId: Todo["id"],
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Todo> {
      const localVarAxiosArgs = TodoApiAxiosParamCreator(
        configuration
      ).getTodoById(todoId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updates a todo
     * @param {Id} todoId ID of todo
     * @param {TodoPatch} [todoPatch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTodo(
      todoId: Todo["id"],
      todoPatch?: TodoPatch,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Todo> {
      const localVarAxiosArgs = TodoApiAxiosParamCreator(
        configuration
      ).updateTodo(todoId, todoPatch, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TodoApi - factory interface
 * @export
 */
export const TodoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Add a new todo
     * @param {TodoPatch} todoPatch The new todo object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTodo(todoPatch: TodoPatch, options?: any): AxiosPromise<Todo> {
      return TodoApiFp(configuration).addTodo(todoPatch, options)(
        axios,
        basePath
      );
    },
    /**
     * Returns a single todo
     * @summary Change position of a todo
     * @param {Id} todoId ID of todo
     * @param {TodoChangePosition} [todoChangePosition] If left empty, the tag is moved to the end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTodoPosition(
      todoId: Todo["id"],
      todoChangePosition?: TodoChangePosition,
      options?: any
    ): AxiosPromise<Todo> {
      return TodoApiFp(configuration).changeTodoPosition(
        todoId,
        todoChangePosition,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Deletes a todo
     * @param {Id} todoId ID of todo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTodo(todoId: Todo["id"], options?: any): AxiosPromise<void> {
      return TodoApiFp(configuration).deleteTodo(todoId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @summary Get all Todos
     * @param {Array<Status>} [status] Status values that need to be considered for filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTodos(
      status?: Array<TodoStatusEnum>,
      options?: any
    ): AxiosPromise<Array<Todo>> {
      return TodoApiFp(configuration).getAllTodos(status, options)(
        axios,
        basePath
      );
    },
    /**
     * Returns a single todo
     * @summary Find todo by ID
     * @param {Id} todoId ID of todo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTodoById(todoId: Todo["id"], options?: any): AxiosPromise<Todo> {
      return TodoApiFp(configuration).getTodoById(todoId, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @summary Updates a todo
     * @param {Id} todoId ID of todo
     * @param {TodoPatch} [todoPatch]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTodo(
      todoId: Todo["id"],
      todoPatch?: TodoPatch,
      options?: any
    ): AxiosPromise<Todo> {
      return TodoApiFp(configuration).updateTodo(
        todoId,
        todoPatch,
        options
      )(axios, basePath);
    },
  };
};

/**
 * TodoApi - object-oriented interface
 * @export
 * @class TodoApi
 * @extends {BaseAPI}
 */
export class TodoApi extends BaseAPI {
  /**
   *
   * @summary Add a new todo
   * @param {TodoPatch} todoPatch The new todo object
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TodoApi
   */
  public addTodo(todoPatch: TodoPatch, options?: any) {
    return TodoApiFp(this.configuration).addTodo(todoPatch, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Returns a single todo
   * @summary Change position of a todo
   * @param {Id} todoId ID of todo
   * @param {TodoChangePosition} [todoChangePosition] If left empty, the tag is moved to the end
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TodoApi
   */
  public changeTodoPosition(
    todoId: Todo["id"],
    todoChangePosition?: TodoChangePosition,
    options?: any
  ) {
    return TodoApiFp(this.configuration).changeTodoPosition(
      todoId,
      todoChangePosition,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Deletes a todo
   * @param {Id} todoId ID of todo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TodoApi
   */
  public deleteTodo(todoId: Todo["id"], options?: any) {
    return TodoApiFp(this.configuration).deleteTodo(todoId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Get all Todos
   * @param {Array<Status>} [status] Status values that need to be considered for filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TodoApi
   */
  public getAllTodos(status?: Array<TodoStatusEnum>, options?: any) {
    return TodoApiFp(this.configuration).getAllTodos(status, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Returns a single todo
   * @summary Find todo by ID
   * @param {Id} todoId ID of todo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TodoApi
   */
  public getTodoById(todoId: Todo["id"], options?: any) {
    return TodoApiFp(this.configuration).getTodoById(todoId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Updates a todo
   * @param {Id} todoId ID of todo
   * @param {TodoPatch} [todoPatch]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TodoApi
   */
  public updateTodo(todoId: Todo["id"], todoPatch?: TodoPatch, options?: any) {
    return TodoApiFp(this.configuration).updateTodo(
      todoId,
      todoPatch,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Gets the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUser(options: any = {}): RequestArgs {
      const localVarPath = `/user/current`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication api_key required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? configuration.apiKey("X-API-KEY")
            : configuration.apiKey;
        localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Gets the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUser(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs =
        UserApiAxiosParamCreator(configuration).getCurrentUser(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Gets the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUser(options?: any): AxiosPromise<User> {
      return UserApiFp(configuration).getCurrentUser(options)(axios, basePath);
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Gets the current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getCurrentUser(options?: any) {
    return UserApiFp(this.configuration).getCurrentUser(options)(
      this.axios,
      this.basePath
    );
  }
}
