import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "./configuration/axios";
import "./configuration/i18n";
import "./configuration/sentry";

import "semantic-ui-css/semantic.min.css";
import App from "./main/js/app";

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./index", () => {
    render();
  });
}
