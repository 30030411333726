import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import { RootState } from "../../store/reducer";

interface Props {}
interface StateProps {
  token: string | undefined;
}
const LoginMenu = (props: Props) => {
  const { token } = useSelector<RootState, StateProps>((state) => {
    return {
      token: state.user.token,
    };
  });

  const login = (
    <Menu.Item as="a" href="/#/login">
      Login
    </Menu.Item>
  );
  const logout = (
    <Menu.Item as="a" href="/#/logout">
      Logout
    </Menu.Item>
  );

  return token === undefined ? login : logout;
};

export default LoginMenu;
