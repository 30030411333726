import React from "react";
import { Header } from "semantic-ui-react";

const AboutPage = () => {
  return (
    <>
      <Header as="h2">About</Header>
      <p>Todo App is the app to manage your todos in 2021.</p>
    </>
  );
};

export default AboutPage;
