import React, { useEffect } from "react";
import { Header } from "semantic-ui-react";
import { TodoList } from "./todo/todoList";
import { RootState } from "../store/reducer";
import { useSelector } from "react-redux";
import { SearchResult, Tag, TagTodoLink } from "../backend/axios/api";
import SearchDispatcher from "../store/search/dispatcher";
import store from "../store/store";
import { useParams } from "react-router";

interface SearchState {
  term: string | undefined;
  tags: Tag[];
  tagTodoLinks: TagTodoLink[];
  searchResult: SearchResult | undefined;
  searchLoading: boolean;
}
const SearchPage = () => {
  const { searchTerm } = useParams();

  const { term, tags, tagTodoLinks, searchResult, searchLoading } = useSelector<
    RootState,
    SearchState
  >((state) => {
    return {
      term: state.search.term,
      tags: state.tag.tags,
      tagTodoLinks: state.tagTodoLink.tagTodoLinks,
      searchResult: state.search.searchResult,
      searchLoading: state.search.loading,
    };
  });

  useEffect(() => {
    const searchDispatch = new SearchDispatcher(store.dispatch);
    if (searchTerm && term !== searchTerm) {
      searchDispatch.search(searchTerm);
    }
  }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchTodos = searchResult?.todos ? searchResult?.todos : [];

  return (
    <>
      <Header as="h2">
        Search results for <i>{term}</i>
      </Header>

      <TodoList
        tags={tags}
        tagTodoLinks={tagTodoLinks}
        todos={searchTodos}
        todosLoading={searchLoading}
        lightweight={true}
      />

      {/*<TagList
        lightweight={true}
        tags={searchTags}
        tagLoading={searchLoading}
      />*/}
    </>
  );
};

export default SearchPage;
