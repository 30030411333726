//inspired by rot13x.js - Topcat Software LLC. 2011

function rot13(s: string): string {
  const rxi = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const rxo = "NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm5678901234";
  let buf = "";

  for (let z = 0; z < s.length; z++) {
    const c = s.charAt(z);
    const index = rxi.indexOf(c);

    if (index !== -1) {
      buf += rxo[index];
    } else {
      buf += c;
    }
  }

  return buf;
}

export function sendEmail(email: string) {
  window.location.href = "mailto:" + email;
}
export function sendRot13Email(email: string) {
  window.location.href = "mailto:" + rot13(email);
}
