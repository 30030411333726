import { Todo } from "./../../backend/axios/api";
import { Action, Reducer } from "redux";

export interface TodoState {
  loading: boolean;
  loaded: boolean;
  todos: Todo[];
  error: boolean;
}

export const initialState: TodoState = {
  loading: false,
  loaded: false,
  todos: [],
  error: false,
};

export enum TodoActionType {
  GET_CURRENT_TODOS_TRIGGER = "GET_CURRENT_TODOS_TRIGGER",
  GET_CURRENT_TODOS_REQUEST = "GET_CURRENT_TODOS_REQUEST",
  GET_CURRENT_TODOS_SUCCESS = "GET_CURRENT_TODOS_SUCCESS",
  GET_CURRENT_TODOS_FAILURE = "GET_CURRENT_TODOS_FAILURE",
  TODOS_CLEAR_ERROR_MESSAGE = "TODOS_CLEAR_ERROR_MESSAGE",
}
export interface TodoDispatchAction extends Action<TodoActionType> {
  payload?:
    | Todo[] // GET_CURRENT_TODOS_REQUEST
    | Todo; // ADD_TODO_REQUEST
}

export const todoReducer: Reducer<TodoState, TodoDispatchAction> = (
  state = initialState,
  action
): TodoState => {
  switch (action.type) {
    case TodoActionType.GET_CURRENT_TODOS_REQUEST:
      return { ...state, loading: true };

    case TodoActionType.GET_CURRENT_TODOS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        todos: Array.isArray(action.payload) ? action.payload : state.todos,
      };
    case TodoActionType.GET_CURRENT_TODOS_FAILURE:
      return { ...state, error: true, loading: false };
    case TodoActionType.TODOS_CLEAR_ERROR_MESSAGE:
      return { ...state, error: false };

    default:
      return state;
  }
};
