import React from "react";
import { useDispatch } from "react-redux";
import { Button, Dimmer, Message, Loader, Modal } from "semantic-ui-react";
import { Trans, useTranslation } from "react-i18next";
import { Todo } from "../../backend/axios/api";
import { TodoService } from "../../backend/axios/connector";
import TodoDispatcher from "../../store/todo/dispatcher";
import { AppNotificationType } from "../../store/notification/reducer";
import NotificationDispatcher from "../../store/notification/dispatcher";

interface Props {
  todo: Todo;
  trigger: React.ReactNode;
}

const TodoDeleteDialog = (props: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const notificationDispatcher = new NotificationDispatcher(dispatch);
  const todoDispatcher = new TodoDispatcher(dispatch);

  const [open, setOpen] = React.useState(false);

  const [deleting, setDeleting] = React.useState(false);
  const [hasDeleteError, setHasDeleteError] = React.useState(false);

  const onSubmit = () => {
    setDeleting(true);

    TodoService.deleteTodo(props.todo.id)
      .then(() => {
        setOpen(false);
        setHasDeleteError(false);

        todoDispatcher.loadTodos();
      })
      .catch((error) => {
        setHasDeleteError(true);

        notificationDispatcher.addNotification({
          type: AppNotificationType.ERROR,
          message:
            error?.response?.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.task.delete",
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <Modal
      size="tiny"
      trigger={props.trigger}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeIcon={true}
    >
      <Modal.Header>
        <Trans i18nKey="todo.dialog.delete.title" />
      </Modal.Header>
      <Modal.Content>
        <Dimmer active={deleting}>
          <Loader>
            <Trans i18nKey="loading" />
          </Loader>
        </Dimmer>

        <Modal.Description>{props.todo.title}</Modal.Description>

        {hasDeleteError && (
          <Message
            error
            header={t("axios.error.server")}
            content={t("axios.error.task.delete")}
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          <Trans i18nKey="todo.dialog.delete.button.cancel" />
        </Button>
        <Button
          onClick={onSubmit}
          negative
          labelPosition="right"
          icon="trash"
          content={<Trans i18nKey="todo.dialog.delete.button.submit" />}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default TodoDeleteDialog;
