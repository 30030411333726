import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import UserDispatcher from "../../store/user/dispatcher";
import { Container, Header } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { useQuery } from "../../../util/urlHelper";

interface Props {}
const LoggedInPage = (props: Props) => {
  let query = useQuery();

  const dispatch = useDispatch();
  const userDispatcher = new UserDispatcher(dispatch);

  useEffect(() => {
    const email = query.get("email") as string;
    const name = query.get("name") as string;
    const token = query.get("token") as string;
    userDispatcher.login(email, name, token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header>Successfully logged in</Header>
      <Container textAlign="center">
        <Redirect to="/home" />
      </Container>
    </>
  );
};

export default LoggedInPage;
