import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Button, Icon, Container, Form, Grid } from "semantic-ui-react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Trans, useTranslation } from "react-i18next";

import { TimerDispatcher } from "../../store/timer/dispatcher";
import { RootState } from "../../store/reducer";
import { TimerState } from "../../store/timer/reducer";

interface Props {}

const TimerPage = (props: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const timerDispatcher = new TimerDispatcher(dispatch);
  const {
    intervalDuration,
    breakDuration,
    longBreakDuration,
    intervalsTillLongBreak,

    tick,
    tickCountdown,
    tickCountdownStr,
    currentInterval,
    timerState,

    tasks,
  } = useSelector<RootState, TimerState>((state) => {
    return { ...state.timer };
  });

  const updateTaskEntry = (text: string, index: number) => {
    const taskCopy = tasks.slice();
    if (0 <= index) {
      taskCopy[index] = text;
    } else {
      taskCopy.push(text);
    }
    for (var i = taskCopy.length - 1; i >= 0; i--) {
      if (taskCopy[i].length === 0) {
        taskCopy.splice(i, 1);
      }
    }

    timerDispatcher.updateTasks(taskCopy);
  };
  const progressBarColorInactive = "lightsteelblue";
  const progressBarColorActive = "lime";

  const durationTotal =
    (intervalDuration + breakDuration) * (intervalsTillLongBreak - 1) +
    (intervalDuration + longBreakDuration);
  const durationIntervalPercentage = (intervalDuration / durationTotal) * 100;
  const durationBreakPercentage = (breakDuration / durationTotal) * 100;
  const durationLongBreakPercentage = (longBreakDuration / durationTotal) * 100;

  return (
    <>
      <Header as="h1">
        <Trans i18nKey="timer.title" />
      </Header>
      <Grid columns={2} doubling verticalAlign="middle">
        <Grid.Column>
          <CircularProgressbar
            value={(tick * 100) / (tick + tickCountdown)}
            text={tickCountdownStr}
            background
            backgroundPadding={2}
            styles={buildStyles({
              backgroundColor: "#3e98c7",
              textColor: "#fff",
              pathColor: "#fff",
              trailColor: "transparent",
              textSize: "14px",
            })}
          />
          <Header as="h2">
            {timerState === "INTERVAL" ? (
              <>
                {t("timer.status", { status: t("timer.progress.interval") })}{" "}
                <Icon name="briefcase" />
              </>
            ) : (
              <>
                {t("timer.status", { status: t("timer.progress.break") })}{" "}
                <Icon name="coffee" />
              </>
            )}
          </Header>
        </Grid.Column>

        <Grid.Column>
          <Header as="h3">{t("timer.tasks.title")}</Header>
          <Form>
            {tasks.map((value, index) => (
              <Form.Group key={index}>
                {index + 1}.
                <Form.Input
                  placeholder={t("timer.tasks.placeholder", {
                    index: index + 1,
                  })}
                  fluid
                  width={16}
                  value={value}
                  onChange={(event, data) => {
                    updateTaskEntry(data.value, index);
                  }}
                />
              </Form.Group>
            ))}
            <Form.Group key={-1}>
              {tasks.length + 1}.
              <Form.Input
                placeholder={t("timer.tasks.placeholder", {
                  index: tasks.length + 1,
                })}
                fluid
                width={16}
                value=""
                onChange={(event, data) => {
                  updateTaskEntry(data.value, -1);
                }}
              />
            </Form.Group>
          </Form>

          <Header as="h3">{t("timer.breaks.title")}</Header>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                type="number"
                label={t("timer.breaks.interval.placeholder")}
                placeholder="25"
                value={intervalDuration}
                onChange={(event, data) => {
                  if (0 < Math.floor(Number(data.value))) {
                    timerDispatcher.updateConfig(
                      Math.floor(Number(data.value)),
                      breakDuration,
                      longBreakDuration,
                      intervalsTillLongBreak
                    );
                  }
                }}
              />
              <Form.Input
                type="number"
                label={t("timer.breaks.break.placeholder")}
                placeholder="5"
                value={breakDuration}
                onChange={(event, data) => {
                  if (0 < Math.floor(Number(data.value))) {
                    timerDispatcher.updateConfig(
                      intervalDuration,
                      Math.floor(Number(data.value)),
                      longBreakDuration,
                      intervalsTillLongBreak
                    );
                  }
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                type="number"
                label={t("timer.breaks.longBreak.placeholder")}
                placeholder="15"
                value={longBreakDuration}
                onChange={(event, data) => {
                  if (0 < Math.floor(Number(data.value))) {
                    timerDispatcher.updateConfig(
                      intervalDuration,
                      breakDuration,
                      Math.floor(Number(data.value)),
                      intervalsTillLongBreak
                    );
                  }
                }}
              />
              <Form.Input
                type="number"
                label={t("timer.breaks.requiredIntervals.placeholder")}
                placeholder="3"
                value={intervalsTillLongBreak}
                onChange={(event, data) => {
                  if (0 < Math.floor(Number(data.value))) {
                    timerDispatcher.updateConfig(
                      intervalDuration,
                      breakDuration,
                      longBreakDuration,
                      Math.floor(Number(data.value))
                    );
                  }
                }}
              />
            </Form.Group>
          </Form>

          <div
            className="ui progress"
            style={{ display: "flex", textAlign: "center" }}
          >
            {Array.from(Array(intervalsTillLongBreak - 1).keys()).map(
              (value, index) => {
                return (
                  <>
                    <div
                      className="bar"
                      style={{
                        width: durationIntervalPercentage + "%",
                        backgroundColor:
                          timerState === "INTERVAL" && currentInterval === index
                            ? progressBarColorActive
                            : progressBarColorInactive,
                      }}
                    >
                      {t("timer.progress.interval")}
                    </div>
                    <div
                      className="bar"
                      style={{
                        width: durationBreakPercentage + "%",
                        backgroundColor:
                          timerState === "BREAK" && currentInterval === index
                            ? progressBarColorActive
                            : progressBarColorInactive,
                      }}
                    >
                      {t("timer.progress.break")}
                    </div>
                  </>
                );
              }
            )}
            <div
              className="bar"
              style={{
                width: durationIntervalPercentage + "%",
                backgroundColor:
                  timerState === "INTERVAL" &&
                  currentInterval === intervalsTillLongBreak - 1
                    ? progressBarColorActive
                    : progressBarColorInactive,
              }}
            >
              {t("timer.progress.interval")}
            </div>
            <div
              className="bar"
              style={{
                width: durationLongBreakPercentage + "%",
                backgroundColor:
                  timerState === "LONGBREAK"
                    ? progressBarColorActive
                    : progressBarColorInactive,
              }}
            >
              {t("timer.progress.longBreak")}
            </div>
          </div>

          <Container textAlign="center">
            <Button
              icon
              size="large"
              onClick={() => timerDispatcher.start()}
              disabled={timerState !== "STOP"}
            >
              <Icon name={"play"} />
              {t("timer.button.start")}
            </Button>
            <Button
              icon
              size="large"
              onClick={() => timerDispatcher.reset()}
              disabled={timerState === "STOP"}
            >
              <Icon name="repeat" />
              {t("timer.button.reset")}
            </Button>
          </Container>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default TimerPage;
