import { Dispatch } from "redux";

import { TagDispatchAction, TagActionType } from "./reducer";

export class TagDispatcher {
  private readonly dispatch: Dispatch<TagDispatchAction>;

  constructor(dispatch: Dispatch<TagDispatchAction>) {
    this.dispatch = dispatch;
  }
  loadTags = () =>
    this.dispatch({
      type: TagActionType.GET_CURRENT_TAGS_TRIGGER,
      payload: {},
    });
}

export default TagDispatcher;
