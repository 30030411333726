import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { AuthService } from "../../backend/axios/connector";
import UserDispatcher from "../../store/user/dispatcher";

interface Props {}
const LogoutPage = (props: Props) => {
  const dispatch = useDispatch();
  const userDispatcher = new UserDispatcher(dispatch);

    AuthService.oAuthLogout().finally(() => {
      userDispatcher.logout();
    });

  return (
    <>
      <Redirect to="/home" />
    </>
  );
};

export default LogoutPage;
