import { Dispatch } from "redux";

import { TimerDispatchAction, TimerActionType } from "./reducer";

export class TimerDispatcher {
  private readonly dispatch: Dispatch<TimerDispatchAction>;

  constructor(dispatch: Dispatch<TimerDispatchAction>) {
    this.dispatch = dispatch;
  }

  start = () =>
    this.dispatch({
      type: TimerActionType.TIMER_START,
      payload: {},
    });
  reset = () =>
    this.dispatch({
      type: TimerActionType.TIMER_RESET,
      payload: {},
    });

  tick = () => {
    this.dispatch({
      type: TimerActionType.TIMER_TICK,
      payload: {},
    });
  };

  updateConfig = (
    intervalDuration: number,
    breakDuration: number,
    longBreakDuration: number,
    intervalsTillLongBreak: number
  ) => {
    this.dispatch({
      type: TimerActionType.TIMER_CONFIG_UPDATE,
      payload: {
        intervalDuration,
        breakDuration,
        longBreakDuration,
        intervalsTillLongBreak,
      },
    });
  };

  updateTasks = (tasks: string[]) => {
    this.dispatch({
      type: TimerActionType.TIMER_TASKS_UPDATE,
      payload: { tasks: tasks },
    });
  };
}

export default TimerDispatcher;
