import {
  NotificationActionType,
  NotificationDispatchAction,
  AppNotificationType,
} from "./../notification/reducer";
import { TodoService } from "../../backend/axios/connector";
import { Todo } from "../../backend/axios/api";
import { TodoActionType, TodoDispatchAction } from "./reducer";
import { all, put, takeLatest, cancelled, cancel } from "redux-saga/effects";

export function fixTodoTypes(todo: Todo) {
  // easier to fix in this layer, as the TodoApi code gets overwritten by code generation
  if (todo.createDate) {
    todo.createDate = new Date(todo.createDate);
  }
  if (todo.dueDate) {
    todo.dueDate = new Date(todo.dueDate);
  }
  return todo;
}

export const getCurrentTodosSaga = function* (action: TodoDispatchAction) {
  yield put({ type: TodoActionType.GET_CURRENT_TODOS_REQUEST, info: {} });
  try {
    const result = yield TodoService.getAllTodos();
    if (result && result.status === 200) {
      const todos: Todo[] = result.data;
      yield put<TodoDispatchAction>({
        type: TodoActionType.GET_CURRENT_TODOS_SUCCESS,
        payload: todos.map((el: Todo) => fixTodoTypes(el)),
      });
    } else {
      yield put<TodoDispatchAction>({
        type: TodoActionType.GET_CURRENT_TODOS_FAILURE,
      });
      yield put<NotificationDispatchAction>({
        type: NotificationActionType.NOTIFICATION_ADD,
        payload: {
          type: AppNotificationType.ERROR,
          message:
            result.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.task.load",
        },
      });
      yield cancel();
    }
  } catch (error) {
    yield put<TodoDispatchAction>({
      type: TodoActionType.GET_CURRENT_TODOS_FAILURE,
    });
  } finally {
    if (yield cancelled()) {
    }
  }
};

export const todoSagas = function* () {
  yield all([
    takeLatest(TodoActionType.GET_CURRENT_TODOS_TRIGGER, getCurrentTodosSaga),
  ]);
};
