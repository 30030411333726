import { ConfigService } from "../../backend/axios/connector";
import { Config } from "../../backend/axios/api";
import { ConfigActionType, ConfigDispatchAction } from "./reducer";
import { all, put, takeLatest, cancelled } from "redux-saga/effects";

export const getConfigSaga = function* (action: ConfigDispatchAction) {
  yield put({ type: ConfigActionType.GET_CONFIG_REQUEST, info: {} });
  try {
    const result = yield ConfigService.getConfig();
    if (result && result.status === 200) {
      const config: Config = result.data;
      yield put<ConfigDispatchAction>({
        type: ConfigActionType.GET_CONFIG_SUCCESS,
        payload: {
          ...config,
        },
      });
    }
  } catch (error) {
    yield put<ConfigDispatchAction>({
      type: ConfigActionType.GET_CONFIG_FAILURE,
    });
  } finally {
    if (yield cancelled()) {
    }
  }
};

export const configSagas = function* () {
  yield all([takeLatest(ConfigActionType.GET_CONFIG_TRIGGER, getConfigSaga)]);
};
