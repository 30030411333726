import { Dispatch } from "redux";

import { TodoDispatchAction, TodoActionType } from "./reducer";

export class TodoDispatcher {
  private readonly dispatch: Dispatch<TodoDispatchAction>;

  constructor(dispatch: Dispatch<TodoDispatchAction>) {
    this.dispatch = dispatch;
  }
  loadTodos = () =>
    this.dispatch({
      type: TodoActionType.GET_CURRENT_TODOS_TRIGGER,
    });
}

export default TodoDispatcher;
