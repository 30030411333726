import axios, { AxiosRequestConfig } from "axios";

let apiKey: string | undefined = undefined;
export const setApiKey = (key: string | undefined) => {
  apiKey = key;
};

const authenticationRequestInterceptor = (config: AxiosRequestConfig) => {
  if (apiKey !== undefined) {
    config.headers.common["X-API-KEY"] = apiKey;
  }
  return config;
};

axios.interceptors.request.use(authenticationRequestInterceptor);
