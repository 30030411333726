import { Dispatch } from "redux";

import { SearchDispatchAction, SearchActionType } from "./reducer";

export class SearchDispatcher {
  private readonly dispatch: Dispatch<SearchDispatchAction>;

  constructor(dispatch: Dispatch<SearchDispatchAction>) {
    this.dispatch = dispatch;
  }
  search = (term: string) =>
    this.dispatch({
      type: SearchActionType.RUN_SEARCH_TRIGGER,
      payload: { term: term },
    });
}

export default SearchDispatcher;
