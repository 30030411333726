import {
  NotificationDispatchAction,
  NotificationActionType,
  AppNotificationType,
} from "./../notification/reducer";
import { SearchService } from "../../backend/axios/connector";
import { SearchActionType, SearchDispatchAction } from "./reducer";
import { all, put, takeLatest, cancelled, cancel } from "redux-saga/effects";
import { SearchRequest, Todo } from "../../backend/axios/api";
import { fixTodoTypes } from "../todo/saga";

export const runSearchSaga = function* (action: SearchDispatchAction) {
  const term = (action.payload as SearchRequest).term;
  yield put({
    type: SearchActionType.RUN_SEARCH_REQUEST,
    payload: { term: term },
  });
  try {
    const result = yield SearchService.search({
      term: term,
    });
    if (result && result.status === 200) {
      yield put<SearchDispatchAction>({
        type: SearchActionType.RUN_SEARCH_SUCCESS,
        payload: {
          searchResult: {
            ...result.data,
            todos: result.data?.todos.map((el: Todo) => fixTodoTypes(el)),
          },
        },
      });
    } else {
      yield put<SearchDispatchAction>({
        type: SearchActionType.RUN_SEARCH_FAILURE,
        payload: {},
      });
      yield put<NotificationDispatchAction>({
        type: NotificationActionType.NOTIFICATION_ADD,
        payload: {
          type: AppNotificationType.ERROR,
          message:
            result.status === 401
              ? "axios.error.unauthorized"
              : "axios.error.search.load",
        },
      });
      yield cancel();
    }
  } catch (error) {
    yield put<SearchDispatchAction>({
      type: SearchActionType.RUN_SEARCH_FAILURE,
      payload: {},
    });
  } finally {
    if (yield cancelled()) {
    }
  }
};

export const searchSagas = function* () {
  yield all([takeLatest(SearchActionType.RUN_SEARCH_TRIGGER, runSearchSaga)]);
};
