import { Dispatch } from "redux";

import { NotificationDispatchAction, NotificationActionType, AppNotification } from "./reducer";

export class NotificationDispatcher {
  private readonly dispatch: Dispatch<NotificationDispatchAction>;

  constructor(dispatch: Dispatch<NotificationDispatchAction>) {
    this.dispatch = dispatch;
  }
  addNotification = (notification: AppNotification) =>
    this.dispatch({
      type: NotificationActionType.NOTIFICATION_ADD,
      payload: notification,
    });
}

export default NotificationDispatcher;
