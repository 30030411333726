import React from "react";
import { Container, List, Segment } from "semantic-ui-react";
import GitInfo from "react-git-info/macro";

const gitInfo = GitInfo();

const AppFooter = () => (
  <Segment
    inverted
    vertical
    style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
  >
    <Container textAlign="center">
      <List horizontal inverted divided link size="small">
        <List.Item href="/#/about">About</List.Item>

        <List.Item href="/#/license">License</List.Item>

        <List.Item href="/#/privacy">Privacy Policy</List.Item>

        <List.Item href="/#/terms">Terms Of Service</List.Item>

        <List.Item href="https://todo-openapi.timonback.de/" target="_blank" rel="noopener noreferrer">
          OpenApi docs
        </List.Item>

        <List.Item>Version: {gitInfo.commit.shortHash}</List.Item>
      </List>
    </Container>
  </Segment>
);

export default AppFooter;
